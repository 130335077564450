import React from "react";
import {
    Typography,
    IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import SimpleTable from "../../components/simpleTable";
import { EWAService } from "../../service";
import Alert from "../../components/alert";
import {  convertTimestamptoDateWithTime } from "../../utils/helper";

const COLS = [
    { key: "id", align: "left", label: "ID", value: "paymentId", type: "text", sort: false, fn: (v) => v },
    { key: "createdDate", align: "left", label: "CREATED DATE", value: "createdDate", type: "text", sort: false, fn: (v) => v ? convertTimestamptoDateWithTime(v) : '-' },
    { key: "paymentMode", align: "left", label: "PAYMENT MODE", value: "paymentMode", type: "text", sort: false, fn: (v) => v },
    { key: "amount", align: "left", label: "AMOUNT", value: "amount", type: "text", sort: false, fn: (v) => v },
    // { key: "userId", align: "left", label: "USER ID", value: "userId", type: "text", sort: false, fn: (v) => v },
    // { key: "message", align: "left", label: "MESSAGE", value: "message", type: "text", sort: false, fn: (v) => v },
    // { key: "username", align: "left", label: "PAYMENT FROM", value: "username", type: "text", sort: false, fn: (v) => v },
    { key: "statementId", align: "left", label: "STATEMENT ID", value: "statementId", type: "text", sort: false, fn: (v) => v },
    // { key: "transactionType", align: "left", label: "TRANSACTION TYPE", value: "transactionType", type: "text", sort: false, fn: (v) => v ? TRANSACTION_TYPE_CODES[v] : '-' },
    // { key: "waiver", align: "left", label: "WAIVER", value: "waiver", type: "text", sort: false, fn: (v) => v ? "YES" : "NO" },
]

class Payment extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            data: [],
            hasError: false,
            errorMessage: "",
        }
    }

    componentDidMount() {
        this.fetchPayments();
    }

    fetchPayments = () => {

        const { id } = this.props;

        EWAService.getEWAPaymentDetail(id,
            (res) => {
                if (res.success) {
                    this.setState({
                        loading: false,
                        data: res.response.paymentDetails
                    })
                } else {
                    this.setState({
                        hasError: true,
                    })
                }
            }
        )
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render() {
        const { loading, data, hasError, errorMessage } = this.state;
        const { classes, handleClose } = this.props;

        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >PAYMENTS</Typography>
                    <SimpleTable
                        cols={COLS}
                        data={data}
                        loading={loading}
                    />
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    }
}

export default withStyles(styles)(Payment);