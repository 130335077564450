import React from "react";
import {
    Typography,
    Button,
    TextField,
    MenuItem,
    IconButton,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { LOAN_STATUS, CREDIT_LINE_STATUS, UW_FINAL_DECISION } from "./../../common/constants";
import { COMPANY } from './../../common/constants';
import { getClPtpAgentList, getCompanyList, getLoanPtpAgentList } from "../../service/api";
import { getLocalStorage } from "../../utils/localStorage";

const ROLE_VALUES = {
    "ADMIN": ["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "USERS": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "NOTFORROLECE": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "LOANS": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "CREDITLINES": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "WAIVERS": ["ROLE_ADMIN", "ROLE_WAIVER", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "MANUALCHECK": ["ROLE_ADMIN", "ROLE_UW", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "UNDERWRITING": ["ROLE_ADMIN", "ROLE_UW", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "SETTLEMENT": ["ROLE_ADMIN", "ROLE_WAIVER", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "CAMPAIGNS": ["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "DEDUCTION": ["ROLE_ADMIN", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "LOANPTP": ["ROLE_ADMIN", "ROLE_CE", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "CREDITLINEPTP": ["ROLE_ADMIN", "ROLE_CE", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "EDITROLE": ["ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "ADDROLE": ["ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "PTPFILTER": ["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"]
}

export default function FilterScreen (props) {
    const [filter, setFilter] = React.useState({});
    const { screen, handleClose, addFilter } = props;
    const [clList, setClList] = React.useState([]);
    const [loanList, setLoanList] = React.useState([]);
    const [companyList, setCompanyList] = React.useState(COMPANY);

    React.useEffect(() => {
        getLoanPtpAgentList((res) => {
            if (res) {
                setLoanList(res);
            } else {
                setLoanList([]);
            }
        })
        getCompanyList((res) => {
            if (res && res.success) {
                setCompanyList(res.response);
            } else {
                setCompanyList(COMPANY);
            }
        })
    }, [])



    const getAllFilters = () => {
        const { role } = getLocalStorage("LmsUserDetails");
        const FIELDS = [

            { id: "user-created-date", field: "createdDate", label: "USER CREATED DATE", active: false, type: "date", screen: ["user"], option: {}, roleValue: ROLE_VALUES.USERS },
            { id: "loan-type", field: "crmLoanStatus", label: "LOAN STATUS", active: false, type: "select", screen: ["loan"], option: LOAN_STATUS, roleValue: ROLE_VALUES.USERS },
            { id: "company-name", field: "company", label: "COMPANY", active: false, type: "select", screen: ["user", "loan", "creditLine"], option: companyList, roleValue: ROLE_VALUES.USERS },
            { id: "dpd-count", field: "dpd", label: "DPD COUNT", active: false, type: "number", screen: ["loan", "ewa"], option: {}, roleValue: ROLE_VALUES.NOTFORROLECE },
            { id: "due-date", field: "dueDate", label: "DUE DATE", active: false, type: "date", screen: ["loan", "ewa"], option: {}, roleValue: ROLE_VALUES.NOTFORROLECE },
            { id: "ewa-application-status", field: "status", label: "APPLICATION STATUS", active: false, type: "select", screen: ["ewa"], option: ["INITIATED", "PARTNER_CONSENT", "CKYC", "KYC", "SELFIE", "ACTIVE"], roleValue: ROLE_VALUES.NOTFORROLECE },
            { id: "uw-docs-final-decison", field: "finalDecisionDocs", label: "FINAL DECISION DOCUMENT", active: false, type: "select", screen: ["underWriting"], option: UW_FINAL_DECISION, roleValue: ROLE_VALUES.USERS },
            { id: "uw-ref-final-decison", field: "finalDecisionRef", label: "FINAL DECISION REFERENCE", active: false, type: "select", screen: ["underWriting"], option: UW_FINAL_DECISION, roleValue: ROLE_VALUES.USERS },
            { id: "uw-application-status", field: "applicationStatus", label: "APPLICATION STATUS", active: false, type: "select", screen: ["underWriting"], option: CREDIT_LINE_STATUS, roleValue: ROLE_VALUES.USERS },
            { id: "rw-name-check-status", field: "name_Match", label: "NAME CHECK STATUS", active: false, type: "select", screen: ["manualReview"], option: ["YES", "NO"], roleValue: ROLE_VALUES.USERS },
            { id: "rw-name-reviewed", field: "reviewed", label: "REVIEWED STATUS", active: false, type: "select", screen: ["manualReview"], option: ["YES", "NO"], roleValue: ROLE_VALUES.USERS },
            { id: "rw-last-action", field: "lastAction", label: "LAST ACTION STATUS", active: false, type: "select", screen: ["manualReview"], option: ["BANK_DETAIL_RESET_WITHOUT_E_MANDATE", "BANK_DETAIL_RESET", "ACCEPT", "REUPLOAD", "REJECT"], roleValue: ROLE_VALUES.USERS, },
            { id: "rw-doc-check", field: "docCheck", label: "DOC CHECK STATUS", active: false, type: "select", screen: ["manualReview"], option: ["YES", "NO"], roleValue: ROLE_VALUES.USERS },
            { id: "ptp-list-Status", field: "status", label: "STATUS", active: false, type: "select", screen: ["clPtpList", "loanPtpList"], option: ["PENDING", "SUCCESS", "BROKEN", "PARTIAL_PAYMENT"], roleValue: ROLE_VALUES.USERS },
            { id: "ptp-loan-agent-list", field: "agentEmail", label: "AGENT EMAIL", active: false, type: "select", screen: ["loanPtpList"], option: loanList, roleValue: ROLE_VALUES.PTPFILTER },
            { id: "ptp-cl-agent-list", field: "agentEmail", label: "AGENT EMAIL", active: false, type: "select", screen: ["clPtpList"], option: clList, roleValue: ROLE_VALUES.PTPFILTER },
        ]
        const roleAdd = role;
        const showList = [];
        for (let obj of FIELDS) {
            for (let item of roleAdd) {
                if (obj.roleValue.includes(item)) {
                    showList.push(obj);
                    break;
                }
            }
        }
        return showList;
    }


    React.useEffect(() => {
        setFilter(props.appliedFilter);
    }, [props.appliedFilter])

    const handleOutput = (evt, dat) => {
        const { id, name, value } = evt.target;
        const { label } = dat;
        console.log("evt id", id, "evt name", name, "evt value", value, "evt label", label);
        setFilter({ ...filter, [name]: value })
    }

    const loadOptions = opts => {
        return opts.map(item => {
            return <MenuItem key={item} value={item}>
                {item}</MenuItem>
        })
    }

    const loadModuleFilter = () => {
        const comps = [];
        var FIELDS = getAllFilters();
        for (let obj of FIELDS) {
            if (obj.screen.includes(screen)) {
                const { id, label, field, type, option } = obj;
                const isSelect = type === "select";
                let TextFieldProps = {
                    id: id,
                    key: id,
                    name: field,
                    select: isSelect,
                    label: label,
                    type: type,
                    value: filter[field] ? filter[field] : "",
                    defaultValue: isSelect ? option[0] : "",
                    isSelect: isSelect,
                    option: option
                }
                comps.push(TextFieldProps);
            }
        } return comps;
    }

    const clearOutput = evt => {
        setFilter({})
    }

    const applyOutput = evt => {
        addFilter(filter);
    }
    const comps = loadModuleFilter();
    return (
        <div style={styles.backContainer}>
            <div style={styles.rootContainer}>
                <Typography
                    style={styles.title}
                    variant="h5"
                    align={"center"}>FILTERS</Typography>

                {comps.map(comp => {
                    const { id, name, type, isSelect, label, option, value } = comp;
                    return <TextField
                        id={id}
                        key={id}
                        label={label}
                        name={name}
                        type={type}
                        select={isSelect}
                        style={styles.textField}
                        value={value}
                        defaultValue={isSelect ? option[0] : null}
                        size="small"
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(evt) => handleOutput(evt, {
                            label: label
                        })}
                        SelectProps={{
                            MenuProps: {
                                MenuListProps: {
                                    style: {
                                        maxHeight: 210
                                    }
                                }
                            }
                        }}
                        variant="outlined"
                    >{isSelect ? loadOptions(option) : null}</TextField>
                })}
                <div style={styles.actionBtnContainer}>
                    <Button
                        variant="contained"
                        style={{ ...styles.clearBtn, ...styles.commonBtn }}
                        disableElevation
                        disableFocusRipple
                        onClick={clearOutput}
                    >CLEAR</Button>
                    <Button
                        variant="contained"
                        style={{ ...styles.applyBtn, ...styles.commonBtn }}
                        disableElevation
                        disableFocusRipple
                        onClick={applyOutput}
                    >APPLY</Button>
                </div>
            </div>
            <IconButton variant="contained"
                style={styles.closeBtn}
                onClick={handleClose}
            ><CloseIcon fontSize={"large"} /></IconButton>
        </div>
    )
}

const styles = {
    backContainer: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    rootContainer: {
        height: 600,
        display: "inherit",
        alignItems: "center",
        flexDirection: "column",
        width: 400,
        borderRadius: 6,
        background: "#fff",
        position: "relative"
    },
    closeBtn: {
        position: "relative",
        background: "transparent",
        color: "#fff",
        bottom: 289
    },
    title: {
        marginTop: 30,
        marginBottom: 10,
        fontFamily: "Poppins"
    },
    textField: {
        margin: 20,
        width: 300
    },
    actionBtnContainer: {
        display: "inline-block",
        position: "absolute",
        bottom: 0
    },
    commonBtn: {
        margin: "0 40px 30px",
        width: 110
    },
    clearBtn: {

    },
    applyBtn: {
        background: "rgb(21, 27, 128)",
        color: "#fff"
    }
}