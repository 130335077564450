export const collectionMoreOptions = [
    { name: "Add Reference", view: "add_reference" },
    { name: "Add Call Logs", view: "add_call_logs" },
    { name: "Add Payment", view: "add_payment" },
    { name: "Assign Agent", view: "assign_agent" },
    { name: "View Call Logs", view: "view_call_logs" },
    { name: "View Statement", view: "view_statement" },
    { name: "View Payment", view: "view_payment" }
]

export const LOAN_STATUS_CODES = {
    "Incomplete": "INCOMPLETE",
    "Data Complete": "DATA_COMPLETE",
    "Bureau Score Complete": "BUREAU_SCORE_COMPLETION",
    "Marketplace Data Pending": "MARKETPLACE_DATA_PENDING",
    "Approved": "APPROVED",
    "Rejected": "REJECTED",
    "Loan Agreement Signed": "LOAN_AGREEMENT_SIGNED",
    "Active": "ACTIVE",
    "Foreclosure": "FORECLOSURE",
    "Closure": "CLOSURE",
    "Not Interested": "NOT_INTERESTED",
    "Settled": "SETTLED",
    "Kyc Pending": "KYC_PENDING",
    "Kyc Complete": "KYC_COMPLETE",
    "Emandate Completed": "E_MANDATE_COMPLETED",
    "Bank Account Verification Complete": "BANK_ACCOUNT_VERIFICATION_COMPLETED",
    "Bank Account Verification Failed": "BANK_ACCOUNT_VERIFICATION_FAILED",
    "Lender Approved": "NBFC_APPROVED",
    "Lender Rejected": "NBFC_REJECTED",
    "Agreement Signed": "AGREEMENT_SIGNED",
    "Ready for Disbursal": "READY_FOR_DISBURSAL",
    "Disbured": "DISBURSED",
    "Manual Bank Verification Pending": "MANUAL_BANK_ACCOUNT_VERIFICATION_PENDING",
    "Manual Bank Verification Completed": "MANUAL_BANK_ACCOUNT_VERIFICATION_COMPLETED",
    "Manual Bank Verification Rejected": "MANUAL_BANK_ACCOUNT_VERIFICATION_REJECTED",
}

export const LOAN_STATUS = [
    "Incomplete", "Data Complete", "Bureau Score Complete",
    "Marketplace Data Pending", "Approved", "Rejected",
    "Loan Agreement Signed", "Active", "Foreclosure", "Not Interested",
    "Settled", "Closure", "Kyc Pending", "Kyc Complete", "Emandate Completed", "Bank Account Verification Complete",
    "Bank Account Verification Failed", "Manual Bank Verification Pending", "Manual Bank Verification Completed", "Manual Bank Verification Rejected", "Lender Approved", "Lender Rejected", "Ready for Disbursal",
    "Disbured"
]

export const REASON_TYPE_CODES = {
    "Number_not_in_service": "NUMBER NOT IN SERVICE",
    "Phone out of coverage": "PHONE OUT OF COVERAGE",
    "Phone switch off": "PHONE SWITCH OFF",
    "Someone else picked up the call": "SOMEONE ELSE PICKED UP THE CALL",
    "Initiated the payment": "INITIATED THE PAYMENT",
    "Requested for a call back": "REQUESTED FOR A CALL BACK",
    "Did not pick up call": "DID NOT PICK UP THE CALL",
    "No outcome": "NO OUTCOME",
    "other": "OTHER"
}

export const REASON_CODES = [
    { label: "NUMBER NOT IN SERVICE", value: "Number_not_in_service" },
    { label: "PHOEN OUT OF COVERAGE", value: "Phone out of coverage" },
    { label: "PHONE SWITCH OFF", value: "Phone switch off" },
    { label: "INITIATED THE PAYMENT", value: "Initiated the payment" },
    { label: "REQUESTED FOR A CALL BACK", value: "Requested for a call back" },
    { label: "REQUESTED FOR A CALL BACK", value: "Requested for a call back" },
    { label: "DID NOT PICK UP THE CALL", value: "Did not pick up call" },
    { label: "NO OUTCOME", value: "No outcome" },
    { label: "OTHER", value: "other" }
]

export const LOAN_REASON_CODES = {
    "0": "APPROVED",
    "1": "DATA NOT MATCHING WITH SWIGGY",
    "2": "MODEL REJECT",
    "3": "KYC Documents Mismatch",
    "4": "REF REJECT",
    "5": "Low Credit Bureau Score",
    "6": "Duplicate Application From The Same PAN",
    "7": "NOT INTERESTED",
    "8": "LEAVING SWIGGY",
    "9": "AMOUNT NOT ENOUGH",
    "10": "PAST DPD",
    "11": "REJECTED PREVIOUSLY & NOT SUBMIT BANK STATEMENT",
    "12": "PENNY TEST REJECT",
    "13": "MODEL REJECT APPROVED",
    "14": "Unverified From Your Employer",
    "15": "Lack Of Credit History",
    "16": "High Existing Debts",
    "17": " Low Income",
    "18": "Poor Loan Repayment History",
    "19": "High Number Of Enquiry in Credit Bureau",
    "66": "COVID",
    "55": "Internal Policy Reject",
    "21": "B2C APPROVED WITHOUT REFERENCE",
    "100": "NOT DISBURSING",
    "101": "QUESS NOT INTERESTED"


}

export const COMPANY_TYPE_CODES = {

}

export const COMPANY = [
    "QUESS CORP", "SWIGGY", "GROFERS", "RAPIDO", "PICKMYWORK", "CIEL HR", "GIGFORCE", "V5 GLOBAL", "INNOVSOURCE", "OTHER", "RIGO", "YES MADAM"
]

export const RELATIONS = [
    "SELF", "FATHER", "MOTHER", "SIBLING", "SPOUSE", "CHILDREN", "OTHER"
]

export const BOUND = [
    { label: "INBOUND", value: "INBOUND" },
    { label: "OUTBOUND", value: "OUTBOUND" }
]

export const LOAN_TYPE_CODES = {
    "0": "LOAN PRODUCT",
    "1": "CREDIT BUILDING",
    "2": "CREDIT LINE"
}

export const SEARCH_TYPE_CODES = {
    "user": [{ label: "User Id", value: "id" }, { label: "Phone", value: "phone" }],
    "loan": [{ label: "Loan Id", value: "loanId" }, { label: "User Id", value: "userId" }, { label: "Phone", value: "phone" }],
    "creditLine": [{ label: "Application Id", value: "applicationId" }, { label: "User Id", value: "userId" }, { label: "Phone", value: "phone" }, { label: "Creditline Id", value: "creditLineId" }],
    "collection": [],
    "payment": [],
    "underwriting": [{ label: "Application Id", value: "applicationId" }, { label: "User Id", value: "userId" }, { label: "Phone", value: "phone" }],
    "waivers": [{ label: "Loan Id", value: "loanId" }, { label: "Statement Id", value: "statementId" }, { label: "Application Id", value: "applicationId" }],
    "manualReview": [{ label: "Loan Id", value: "loanId" }],
    "settlement": [{ label: "Loan Id", value: "loanId" }],
    "loanPtpList": [{ label: "Loan Id", value: "loanId" }, { label: "User Id", value: "userId" }, { label: "Phone", value: "phone" }],
    "clPtpList": [{ label: "Application Id", value: "applicationId" }, { label: "User Id", value: "userId" }, { label: "Phone", value: "phone" }],
    "ewa": [
        { label: 'Application Id', value: 'applicationId' },
        { label: 'Phone Number', value: 'phone' },
    ]

}

export const TRANSACTION_TYPE_CODES = {
    0: 'WAIVER',
    1: 'PAYMENT',
}

export const PRODUCTS = [
    { label: 'Loan', value: 'loan' },
    { label: 'EWA', value: 'ewa' }
]

export const CREDIT_LINE_STATUS = [
    "INCOMPLETE",
    "DATACOMPLETE",
    "MARKETPLACE_DATA_PENDING",
    "BUREAU_SCORE_COMPLETE",
    "APPROVED",
    "REJECTED",
    "TNCSIGNED",
    "ACTIVE",
    "NOTINTERESTED",
    "INACTIVE",
    "IN_PROCESS"
]

export const CREDIT_LINE_STATUS_CODES = {
    "INCOMPLETE": 0,
    "DATACOMPLETE": 1,
    "BUREAU_SCORE_COMPLETE": 2,
    "MARKETPLACE_DATA_PENDING": 3,
    "APPROVED": 4,
    "REJECTED": 5,
    "TNCSIGNED": 6,
    "ACTIVE": 7,
    "TO_BE_DECIDED_1": 8,
    "TO_BE_DECIDED_2": 9,
    "TO_BE_DECIDED_3": 10,
    "TO_BE_DECIDED_4": 11,
    "TO_BE_DECIDED_5": 12,
    "QUEUED": 13,
    "VERIFY": 14,
    "NOTINTERESTED": 15,
    "INACTIVE": 16,
    "IN_PROCESS": 17,
    "BUREAU_SCORE_PENDING": 18
}

export const Option_for_role = [
    "COLLECTION EXECUTIVE", "CUSTOMER CARE EXECUTIVE", "UNDERWRITING EXECUTIVE"
]


export const lms_role = [
    { key: "ADMIN", value: "ROLE_ADMIN" },
    { key: "COLLECTION EXECUTIVE", value: "ROLE_CE" },
    { key: "CUSTOMER CARE EXECUTIVE", value: "ROLE_CCE" },
    { key: "UNDERWRITING EXECUTIVE", value: "ROLE_UW" },
    { key: "WAIVER ", value: "ROLE_WAIVER" },
    { key: "DEDUCTION PAYMENT", value: "ROLE_DP" },
    { key: "LOAN CANCELLATION", value: "ROLE_LC" },
    { key: "MP REQUEST", value: "ROLE_MP_REQUEST" },

    { key: "LOAN SETTLEMENT", value: "ROLE_LS" },
    { key: "CRM ADMIN", value: "ROLE_CRM_ADMIN" },
    { key: "CAMPAIGN", value: "ROLE_CAMPAIGN" },
    { key: "MANUAL REVIEW READ ONLY", value: "ROLE_MRRO" },
    { key: "MANUAL REVIEW READ WRITE ", value: "ROLE_MRRW" },
    { key: "ADMIN READ ONLY", value: "ROLE_ADMIN_RO" },
    { key: "KYC RESET", value: "ROLE_KDR" },

]

export const rolePermission = {
    "roleAdmin": [

        "credit-line-app-detail-col",
        "credit-line-kyc-detail-col",
        "credit-line-emandate-detail-col",
        "credit-line-digilocker-pan-col",
        "credit-line-digilocker-aadhaar-col",
        "view-click-detail",

        "user-detail",
        "loan-detail",
        "loan-application-detail",
        "disbursement-detail",
        "bank-detail",
        "emandate-detail",
        "loan-kyc-detail",
        "view-payment-detail",
        "loan-emandate-charges",
        "generate-payment-link",

        "user-detail-col",
        "kyc-detail-col",
        "loan-history-col",
        "credit-line-col",

    ],
    "roleCollectionExecutive": [
        // Creditline permission
        "credit-line-app-detail-col",
        "view-emandate-detail",
        // "credit-line-kyc-detail-col",
        // "view-transaction-detail",

        // loan permission
        "user-detail",
        "loan-detail",
        "loan-application-detail",
        "disbursement-detail",
        // "bank-detail",
        // "emandate-detail",
        // "loan-kyc-detail",
        "view-payment-detail",

        // user permission
        // "user-detail-col",
        // "kyc-detail-col",
        // "loan-history-col",
        // "credit-line-col",
    ],
    "roleCustomerCareExecutive": [
        // Creditline permission
        "credit-line-app-detail-col",
        "view-emandate-detail",
        // "credit-line-kyc-detail-col",
        "view-transaction-detail",
        // loan permission

        "user-detail",
        "loan-detail",
        "loan-application-detail",
        "disbursement-detail",
        "bank-detail",
        "emandate-detail",
        // "loan-kyc-detail",
        "view-payment-detail",

        // user permission
        "user-detail-col",
        // "kyc-detail-col",
        "loan-history-col",
        "credit-line-col",
    ],
    "roleUnderwritingExecutive": [
        // Creditline permission
        "credit-line-app-detail-col",
        "view-emandate-detail",
        "credit-line-kyc-detail-col",
        "view-transaction-detail",

        // loan permission
        "user-detail",
        "loan-detail",
        "loan-application-detail",
        "disbursement-detail",
        "user-detail-col",
        "kyc-detail-col",
        "loan-history-col",
        "credit-line-col",
    ],
}

export const UW_STATUS_OPTIONS = [

    { key: "Reject", value: 0 },
    { key: "Doubtful", value: 1 },
    { key: "Accept", value: 2 },
]

export const UW_FINAL_DICISION_OPTIONS = [
    { key: "Rejected", value: 0 },
    { key: "Doubtful", value: 1 },
    { key: "Accepted", value: 2 },
    { key: "Not Interested", value: 3 },
    { key: "Pending", value: 4 }
]

export const UW_FINAL_DECISION = [
    "REJECTED", "DOUBTFUL", "ACCEPTED", "NOT INTERESTED", "PENDING"
]
export const UW_FINAL_DECISION_STATUS_CODES = {
    "REJECTED": 0,
    "DOUBTFUL": 1,
    "ACCEPTED": 2,
    "NOT INTERESTED": 3,
    "PENDING": 4
}

export const UW_FINAL_DECISION_REASON_CODES = {
    "0": "REJECTED",
    "1": "DOUBTFUL",
    "2": "ACCEPTED",
    "3": "NOT INTERESTED",
    "4": "PENDING",
}

export const UW_REF_CONTACTABLE = [
    { key: "No", value: 0 },
    { key: "Yes", value: 1 },
]

export const UW_REF_RELATION = [
    { key: "Mother", value: 0 },
    { key: "Father", value: 1 },
    { key: "Brother", value: 2 },
    { key: "Sister", value: 3 },
    { key: "Friend", value: 4 },
    { key: "Relative", value: 5 },
    { key: "Wife", value: 6 },
    { key: "Husband", value: 7 },
    { key: "Neighbour", value: 8 },
    { key: "Co Worker", value: 9 },
    { key: "Not Known", value: 10 },
]

export const UW_CREDIT_LINE_STATUS_CODES = {
    "0": "INCOMPLETE",
    "1": "DATACOMPLETE",
    "2": "BUREAU_SCORE_COMPLETE",
    "3": "MARKETPLACE_DATA_PENDING",
    "4": "APPROVED",
    "5": "REJECTED",
    "6": "TNCSIGNED",
    "7": "ACTIVE",
    "8": "TO_BE_DECIDED_1",
    "9": "TO_BE_DECIDED_2",
    "10": "TO_BE_DECIDED_3",
    "11": "TO_BE_DECIDED_4",
    "12": "TO_BE_DECIDED_5",
    "13": "QUEUED",
    "14": "VERIFY",
    "15": "NOTINTERESTED",
    "16": "INACTIVE",
    "17": "IN_PROCESS",
    "18": "BUREAU_SCORE_PENDING"
}

export const WAIVER_REQUEST_STATUS = {
    "0": "PENDING",
    "1": "ACCEPTED",
    "2": "REJECTED"
}
