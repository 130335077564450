import React from "react";
import {
    Typography,
    Grid,
    Button
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { convertFromCamelCase } from "./../utils/helper";

import CustomModal from './modal';
import Payment from '../screens/loan/payment';

import CreditLineStatement from "./../screens/creditLine/creditLineStatement";
import CreditLineEmandate from "./../screens/creditLine/creditLineEmandate";
import CreditLinePayment from "./../screens/creditLine/creditLinePayment";
import LoanEmandate from "../screens/loan/loanEmandate";
import LoanStatement from "../screens/loan/loanStatement";
import GenerateLoanPaymentLink from "./loan/loanPaymentLink";
import GenerateCreditLinePaymentLink from "./creditLine/clPaymentLink";
import LoanAllPayement from "../screens/loan/loanAllPayement";
import PromiseToPayLoan from "./loan/promiseToPayloan";
import PromiseToPayCreditLine from "./creditLine/promiseToPayCl";

import RaiseLoanSettlement from "./loan/raiseSettlement";
import CustomImage from "./imageView";

import imageNotAvailable from './../assets/imageNotAvailable.png';
import LoanNotInterested from "./loan/loanNotInterested";
import LoanCancel from "./loan/loanCancel";
import CreditLineAllPayment from "../screens/creditLine/creditLineAllPayment";
import LoanDeductionDetails from "./loan/loanDeductionDetails";
import EwaPayment from "../screens/ewa/ewa-payment";
import EWAStatement from "../screens/ewa/ewa-statement";
import EwaWithdrawal from "../screens/ewa/ewa-withdrawal";

function UpdatePaymentButton (props) {
    const { loanId } = props;
    return (
        <Button
            variant="contained"
            color="default"
            onClick={() => console.log('hello')}
            style={{
                backgroundColor: "rgb(16,12,109)",
                color: "white",
                borderRadius: 5,
            }}
        >UPDATE</Button>

    )
}

export default function Detail (props) {

    const { data, handleBack, loading, id, bankTransfer } = props;

    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalChildren, setModalChildren] = React.useState(null);

    const handleModal = () => {
        if (!modalOpen) setModalOpen(true);
        else setModalOpen(false);
    };

    const singleHead = (heading) => {
        return (
            <div style={styles.singleHead}>
                {heading.map(item => <div style={styles.singleHeadCell}>{convertFromCamelCase(item)}</div>)}
            </div>
        )
    }


    const showLess = (item, key) => {
        let tempStr = "";
        let tempLen = 0;
        if (key === "bank-verification-comment" && item) {
            if (item.length < 25) { tempLen = item.length; }
            else if (item.length > 18) { tempLen = 30; }
            else { tempLen = item.length; }
        } else if (item) {
            if (item.length < 18) { tempLen = item.length; }
            else if (item.length > 18) { tempLen = 18; }
            else { tempLen = item.length; }
        }
        for (let i = 0; i < tempLen; i++) {
            if (item[i] === ",") { tempStr = tempStr + " , " }
            else { tempStr = tempStr + item[i] }
        }
        return <div title={item}>{tempStr}</div>;
    }

    const singleRow = data => {
        return (
            <div style={styles.singleRowContainer}>
                {data.map(item => <div style={styles.singleRowCell} title={item}>
                    {item === "loanStatus" ? showLess(item) : item}
                </div>)}
            </div>
        )
    }

    const setCurrentModalView = view => {
        console.log(view);
        switch (view) {
            case "loan-payment-detail":
                setModalChildren(<Payment id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "ewa-payment-detail":
                setModalChildren(<EwaPayment id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "credit-line-statement-detail":
                setModalChildren(<CreditLineStatement id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "credit-line-emandate-detail":
                setModalChildren(<CreditLineEmandate id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "ewa-withdrawal-detail":
                setModalChildren(<EwaWithdrawal bankTransfer={bankTransfer} id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "credit-line-payment":
                setModalChildren(<CreditLinePayment id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "emandate-charge-history":
                setModalChildren(<LoanEmandate id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "loan-statement-detail":
                setModalChildren(<LoanStatement bankTransfer={bankTransfer} id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "ewa-statement-detail":
                setModalChildren(<EWAStatement bankTransfer={bankTransfer} id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "loan-payment-link":
                setModalChildren(<GenerateLoanPaymentLink id={id} callBack={() => setModalOpen(false)} handleClose={() => setModalOpen(false)} />)
                break;
            case "cl-payment-link":
                setModalChildren(<GenerateCreditLinePaymentLink id={id} callBack={() => setModalOpen(false)} handleClose={() => setModalOpen(false)} />)
                break;
            case "all-loan-payment-detail":
                setModalChildren(<LoanAllPayement id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "promise-to-pay-loan":
                setModalChildren(<PromiseToPayLoan id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "promise-to-pay-creditLine":
                setModalChildren(<PromiseToPayCreditLine id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "raise-loan-settlement":
                setModalChildren(<RaiseLoanSettlement id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "loan-ni-button":
                setModalChildren(<LoanNotInterested id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "loan-cancel-button":
                setModalChildren(<LoanCancel id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "credit-line-all-payment-detail":
                setModalChildren(<CreditLineAllPayment id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "all-loan-deduction-detail":
                setModalChildren(<LoanDeductionDetails id={id} handleClose={() => setModalOpen(false)} />)
                break;
            case "update-payment":
                return <UpdatePaymentButton loanId={id} />
            default:
                setModalChildren(<div>Not found</div>)
        }
        handleModal();
    }

    const handleImage = (item) => {
        setModalChildren(<CustomImage item={item} handleClose={() => setModalOpen(false)} />)
        handleModal();
    }

    const makeImageRow = (data, loading) => {
        const textKeys = ['icCKYCEnabled', 'dobOnAadhaar', 'ckycStatus', 'aadhaarFromDigilocker', 'kyc_type']
        return (
            <Grid container item xs={12} spacing={3}>
                {data.map((item, i) => <Grid key={item.key} item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>{item.label}</Typography>
                    {textKeys.includes(item.key) ? <Typography style={styles.fieldValue}>{item.value}</Typography> :
                        item.value && <div onClick={() => { handleImage(item) }}>
                            <img
                                key={item.key}
                                src={`${item.value}`}
                                alt={`${item.label} _not_found`}
                                style={{
                                    width: 250,
                                    height: 200,
                                    border: '2px solid',
                                    borderRadius: 6,
                                    objectFit: 'contain',
                                }}
                            />
                        </div>
                    }
                </Grid>)}
            </Grid>
        )
    }

    const makeLinkRow = data => {
        return (
            <Grid container item xs={12} spacing={3}>
                {data.map((item, i) => <Grid key={`${item.key}_${i}`} item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>{item.label}</Typography>
                    <span style={styles.link} onClick={() => {
                        setCurrentModalView(item.key);
                    }}>{item.value}</span>
                </Grid>)}
            </Grid>
        );
    }

    const makeTableRow = (data) => {
        if (data.length > 0) {
            return (
                <div style={styles.borderContainer}>
                    {singleHead(Object.keys(data[0]))}
                    {data.map(item => singleRow(Object.values(item)))}
                </div>
            )
        }
    }

    const makeRow = (data, loading) => {
        return (
            <Grid container item xs={12} spacing={3}>
                {data.map((item, i) => <Grid key={item.key} item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>{item.label}</Typography>
                    <Typography style={styles.fieldValue}>
                        {
                            item.key === "bank-verification-comment"
                                || item.key === "loan-cancelation-comment"
                                || item.key === "loan-reason-code-msg"

                                ? showLess(item.value, item.key) : item.value ?? "-"}</Typography>
                </Grid>
                )}
            </Grid>
        );
    }

    const makeLoanCancel = data => {
        return (
            <Grid container item xs={12} spacing={3}>
                {data.map((item, i) => <Grid key={item.key} item xs={3} style={styles.fieldWrapper}>
                    <Button style={styles.cancelBtn} onClick={() => {
                        setCurrentModalView(item.key);
                    }}>{item.value}</Button>
                </Grid>)}
            </Grid>
        );
    }

    const makeHead = (data, loading) => {

        const viewMapper = {
            'loan-kyc-detail': (item) => makeImageRow(item),
            'ewa-kyc-detail': (item) => makeImageRow(item),
            'loan-ckyc-document-col': (item) => makeImageRow(item),
            'credit-line-kyc-detail-col': (item) => makeImageRow(item),
            'loan-history-col': (item) => makeTableRow(item),
            'view-payment-detail': (item) => makeLinkRow(item),
            'view-click-detail': (item) => item,
            'credit-line-digilocker-aadhaar-col': (item) => makeImageRow(item),
            'loan-digilocker-aadhaar-col': (item) => makeImageRow(item),
            'loan-emandate-charges': (item) => makeLinkRow(item),
            'generate-payment-link': (item) => makeLinkRow(item),
            "cancel-ni-loan": (item) => makeLoanCancel(item),
            'default': (item) => makeRow(item)
        }

        return (
            <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                {data.map(item => <div key={item.key}>
                    {item.viewPermission ?
                        <div>
                            <Typography variant="h6" gutterBottom style={styles.header}>{item.label}</Typography>
                            {viewMapper[item.key] ? viewMapper[item.key](item.value) : viewMapper['default'](item.value)}
                        </div> :
                        null
                    }
                </div>)}
            </Grid>
        );
    }

    return (
        <div style={styles.rootContainer}>
            <div style={styles.backBar}>
                <Button
                    variant="contained"
                    color="default"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleBack}
                    style={styles.backIcon}
                >
                    Back
                </Button>
            </div>

            {makeHead(data, loading)}

            <CustomModal
                isOpen={modalOpen}
                handleClose={handleModal}
            >{<>
                {modalChildren}
            </>}</CustomModal>
        </div>
    );
}

const styles = {
    rootContainer: {
        flowGrow: 1,
        background: "#fff",
        padding: 20,
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    link: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    cancelBtn: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        },
        color: "black"
    },
    container: {
        padding: "20px 60px"
    },
    backBar: {
        position: 'absolute',
        marginLeft: -10,
    },
    backIcon: {
        // width:50,
        backgroundColor: "#F0F8FF",
        color: "black",
        borderRadius: 5,
    },
    header: {
        marginTop: 30,
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    fieldLabel: {
        fontSize: 18,
        fontFamily: "inherit",
        color: "#9E9E9E"
    },
    fieldValue: {
        fontSize: 17,
        fontFamily: "inherit",
        whiteSpace: 'break-spaces',
        overflowWrap: 'break-word',
    },
    singleHead: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: 30,
        borderBottom: "1px solid"
    },
    singleRowContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: 40
    },
    borderContainer: {
        border: "1px solid"
    },
    singleHeadCell: {
        width: 200,
        textAlign: "center"
    },
    singleRowCell: {
        width: 200,
        textAlign: "center"
    }
}