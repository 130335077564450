import React from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    IconButton,
    Menu,
    MenuItem
} from "@material-ui/core";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from "@material-ui/core/styles";
import CustomModal from "./../components/modal";
import UserInfo from "./../screens/collection/userInfo";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ReferenceView from "./../screens/collection/reference";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ContentLoader from "./../components/contentLoader";
import CustomTableCell from "./../components/tableCell";
import CallLog from "./../screens/collection/view_call_log";
import Payment from "./../screens/collection/view_payment";
import CustomPopover from "./../components/popover";
import CommentBox from "./../components/commentBox";
import Statement from "./../screens/collection/statement";
import AddReference from "./../screens/collection/add_reference";
import AddCallLog from "./../screens/collection/add_calllog";
import AddPayment from "./../screens/collection/add_payment";
import AssignAgent from "./../screens/collection/assign_agent";
import { findIdFieldDetail } from "./../utils/helper";

import LoanAmortSchedule  from "../screens/loan/amortSchedule"
import BankDetailReset from "./manualReview/bankDetailReset";

import LoanStatementDownload from "./loan/downloadStatement";
import CreditLineStatementDownload from "./creditLine/downloadStatement";

import LoanSettlementAction from "./loan/settlementAction"
import LoanCancel from "./loan/loanCancel";

import ClearIcon from '@material-ui/icons/Clear';

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import LoansettlementSummary from "./loan/loansettlementSummary";
import ActionGoToPage from "./goToPage";

import ResetLoanKycDetail from "../components/loan/resetKycDetail"


import FeedbackIcon from '@material-ui/icons/Feedback';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DownloadLoanAgreement from "./loan/downloadLoanAgreement";

class CustomTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isPopover: false,
            isMenu: false,
            modalChildren: <div>Loading...</div>,
            anchorEl: null,
            rowData: null
        }
    }

    loadView = (view, data) => {
        const { fxns, option } = this.props;
        switch (view) {
            case "user_info":
                return (
                    <UserInfo
                        handleClose={this.handleClose}
                        data={fxns.user_info}
                    />
                );
            case "reference":
                return (
                    <ReferenceView
                        handleClose={this.handleClose}
                        data={data}
                    />
                );
            case "add_reference":
                return (
                    <AddReference
                        handleClose={this.handleClose}
                    />
                );
            case "add_call_logs":
                return (
                    <AddCallLog
                        handleClose={this.handleClose}
                    />
                );
            case "add_payment":
                return (
                    <AddPayment
                        handleClose={this.handleClose}
                    />
                );
            case "assign_agent":
                return (
                    <AssignAgent
                        handleClose={this.handleClose}
                    />
                );
            case "view_call_logs":
                return (
                    <CallLog
                        handleClose={this.handleClose}
                    />
                );
            case "view_payment":
                return (
                    <Payment
                        handleClose={this.handleClose}
                    />
                );
            case "comment":
                return (
                    <CommentBox data={option} />
                );
            case "emi_details":
                return <Statement
                    loanId={data.loanId}
                    handleClose={this.handleClose}
                />
            case "amortSchedule" :
                return <LoanAmortSchedule
                id={data.loanId}
                handleClose={this.handleClose}
            />
            case "loanStatementDownload" :
                return <LoanStatementDownload
                id={data.loanId}
                handleClose={this.handleClose}
            />
            case "loanAgreementDownload" :
                return <DownloadLoanAgreement
                id={data.loanId}
                handleClose={this.handleClose}
            />
            case "clStatementDownload" :
                return <CreditLineStatementDownload
                id={data.applicationId}
                handleClose={this.handleClose}
            />
            case "loanSettlementAction":
                return <LoanSettlementAction
                id={data.loanId}
                requestId={data.id}
                handleClose={this.handleClose}/>

            case "resetBankDetail" :
                return <BankDetailReset
                id={data.loanId}
                handleClose={this.handleClose}
            />

            case "cancelLoan" :
                return <LoanCancel
                id={data.loanId}
                handleClose={this.handleClose}
            />

            case "loanResetKycDoc" :
                return <ResetLoanKycDetail
                id={data.loanId}
                handleClose={this.handleClose}
            />

            case "loanSettlementSummary" :
                return <LoansettlementSummary
                id={data.loanId}
                handleClose={this.handleClose}
            />

            case "clPtpComment":
                return <div title={data.comment}>
                </div>

            case "loanPtpComment":
                return <div title={data.comment}>
                </div>
            default:
                return
        }
    }

    openPopover = (evt, data, view) => {
        const children = this.loadView(view);
        this.setState({
            modalChildren: children,
            anchorEl: evt.currentTarget,
            isPopover: true
        })
        evt.stopPropagation();
    }

    closePopover = _ => {
        this.setState({
            isPopover: false,
            anchorEl: null,
            modalChildren: <div>Exiting...</div>
        })
    }

    openModal = (evt, data, view) => {
        const children = this.loadView(view, data);
        this.setState({
            modalChildren: children,
            isOpen: true
        })
        evt.stopPropagation();
    }

    handleClose = _ => {
        this.setState({
            isOpen: false,
            modalChildren: <div>Exiting...</div>
        })
    }

    openMenu = (evt, data) => {
        this.setState({
            anchorEl: evt.currentTarget,
            rowData: data,
            isMenu: true
        })
        evt.stopPropagation();
    }

    closeMenu = _ => {
        this.setState({
            isMenu: false,
            anchorEl: null,
            rowData: null
        })
    }

    handleCols = (data, column, evt) => {
        const { classes } = this.props;
        const { type } = column;
        switch (type) {
            case "user_info":
                return <div
                    className={classes.link}
                    onClick={(evt) => this.openModal(evt, data.loanID, type)}
                >{data[column.value]}</div>
            case "reference":
                return <IconButton
                    title={"Reference"}
                    onClick={(evt) => this.openModal(evt, data.reference, type)}
                ><PeopleAltIcon /></IconButton>
            case "option":
                return <IconButton
                    title={"More"}
                    onClick={(evt) => this.openMenu(evt, data)}
                ><MoreVertIcon /></IconButton>
            case "comment":
                return <IconButton
                    title={data.comment}
                    onClick={(evt) => this.openPopover(evt, data, type)}
                ><EditIcon /></IconButton>
            case "emi_details":
                return <button
                    className={classes.linkBtn}
                    onClick={(evt) => this.openModal(evt, data, type)}
                >View</button>
            case "amortSchedule":
                return<IconButton
                    onClick={(evt) => this.openModal(evt, data, type)}
                ><RemoveRedEyeIcon/></IconButton>

            case "roleEditRole":
                return <IconButton
                    title={data.comment}
                    onClick={() => this.handleEditRole(data)}
                ><EditIcon /></IconButton>
            case "loanStatementDownload":
                return <IconButton
                    disabled={data.loanStatus==='INCOMPLETE'?true:false}
                    onClick={(evt) => this.openModal(evt, data, type)}
                ><CloudDownloadIcon/></IconButton>
            case "loanAgreementDownload":
                return <IconButton
                disabled={["TNCSIGNED","ACTIVE","FORECLOSURE","CLOSURE"].includes(data.loanStatus)?false:true}
                onClick={(evt) => this.openModal(evt, data, type)}
            ><CloudDownloadIcon/></IconButton>
            case "clStatementDownload":
                return <IconButton
                    disabled={data.loanStatus==='INCOMPLETE'?true:false}
                    onClick={(evt) => this.openModal(evt, data, type)}
                ><CloudDownloadIcon/></IconButton>
            case "loanSettlementAction":
                return <button
                    style={{
                        ...styles.linkBtn,
                        backgroundColor: data.settlementStatus==="PENDING"?"rgb(16,12,109)":"#dddddd",
                        color: data.settlementStatus==="PENDING"?"black":"#fff",
                    }}
                    disabled={data.settlementStatus==="PENDING"?false:true}
                    onClick={(evt) => this.openModal(evt, data, type)}
                >Action</button>


            case "resetBankDetail":
                return <button
                    disabled={data.reviewed?true:false}
                    className={classes.resetBtn}
                    style={{
                        backgroundColor:data.reviewed?"#dddddd":"rgba(16,12,109, 0.9)",
                        color:data.reviewed?"grey":"white",
                    }}
                    onClick={(evt) => this.openModal(evt, data, type)}
                >RESET</button>

            case "loanResetKycDoc":
                return <IconButton
                disabled={data.loanStatus!=="INCOMPLETE"?true:false}
                onClick={(evt) => this.openModal(evt, data, type)}
                size="small"
                style={{
                    backgroundColor:data.loanStatus!=="INCOMPLETE"?"#dddddd":"rgba(16,12,109, 0.9)",
                    color:data.reviewed?"grey":"white",
                }}
            ><RotateLeftIcon/></IconButton>


            case "cancelLoan":
                return <IconButton
                disabled={data.cancelable?false:true}
                onClick={(evt) => this.openModal(evt, data, type)}
            ><ClearIcon/></IconButton>

            case "loanSettlementSummary":
                return <IconButton
                onClick={(evt) => this.openModal(evt, data, type)}
            ><RemoveRedEyeIcon/></IconButton>


            case "clPtpComment":
                return <div title={data.comment}>
                     <FeedbackIcon/>
                </div>

            case "loanPtpComment":
                return <div title={data.comment}>
                    <FeedbackIcon/>
                </div>

            default:
                return column.fn(data[column.value])
        }
    }


    handleEditRole = (data)=>{
        const { location, history } = this.props;
        const pathName = location.pathname;
        const field = findIdFieldDetail(data);
        history.push(pathName + "/editRole/" + data[field]);
    }

    handleReviewBankReset = (data)=>{
       alert(data)
    }

    handleDetails = (data) => {
        const { location, history } = this.props;
        const pathName = location.pathname;
       // localStorage.setItem("nameMatchDetail",data);
        const CAN_VIEW_DETAILS = pathName.includes("user") || pathName.includes("loan") || pathName.includes("ewa") || pathName.includes("creditLine")||
                                 pathName.includes("underWriting")|| pathName.includes("waivers") || pathName.includes("manualCheck");
        if (CAN_VIEW_DETAILS && !pathName.includes("settlement")) {
            const field = findIdFieldDetail(data);
            history.push(pathName + "/" + data[field]);
        }
    }

    handleSort = (evt, property) => {
        console.log("sort by...", property);
        this.props.handleSort(property);
    }

    render() {
        const { classes, data, cols, option, loading, location, count, page, handlePageChange, sort,pageNumberValue,
            handleGoToPageChange,onChangeGoToValue,pageButton} = this.props;

        const { isOpen, modalChildren, anchorEl, isPopover, isMenu } = this.state;
        if (loading) {
            const { pathname } = location;
            const pathName = pathname.split("/")[2];
            return <ContentLoader numberOfLines={7} module={pathName} />
        }
        return (<>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="custom-table">
                    <TableHead>
                        <TableRow className={classes.tableRowHead}>
                            {cols.map(item =>
                                <CustomTableCell
                                    key={item.key}
                                    align={item.align}
                                    sort={sort}
                                    item={item}
                                    handleSort={this.handleSort}
                                >{item.label !== "BLANK" ? item.label : " "}</CustomTableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, i) =>
                            <TableRow hover role="checkbox" tabIndex={-1} key={i} className={classes.tableRowBody}
                                onClick={() => this.handleDetails(item)}
                            >
                                {cols.map((column, i) => <TableCell align={column.align}
                                    className={classes.tableCell}
                                    key={i}
                                >{this.handleCols(item, column)}</TableCell>)}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                count={count}
                page={page}
                onChange={handlePageChange}
                size="large"
                className={classes.paginationWrap}
            />


            {pageButton?
                <ActionGoToPage
                pageNumberValue={pageNumberValue}
                handleGoToPageChange={handleGoToPageChange}
                onChangeGoToValue={onChangeGoToValue}
            />:null}


            <CustomModal
                isOpen={isOpen}
                handleClose={this.handleClose}
            >{modalChildren}</CustomModal>

            <Menu
                id="option-menu"
                anchorEl={anchorEl}
                keepMounted
                open={isMenu}
                onClose={this.closeMenu}
            >{option.map((item, i) => <MenuItem key={i}
                onClick={(evt) => this.openModal(evt, {}, item.view)}
            >{item.name}</MenuItem>)}</Menu>

            <CustomPopover
                isOpen={isPopover}
                anchorEl={anchorEl}
                handleClose={this.closePopover}
            >{modalChildren}</CustomPopover>
        </>);
    }
}

const styles = {
    tableContainer: {
        height: "calc(100vh - 236px)",
    },
    tableRowHead: {
            "& .MuiTableCell-head": {
                color: "black",
                fontWeight:"bold",
                backgroundColor: "#F0F8FF"
            },
    },
    tableRowBody: {
        background: "#fff",
        height: 51.5
    },
    linkBtn: {
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer"
        },
        height: 30,
        width: 80,
        color: "black",
        fontWeight:"bold",
        borderWidth:0,
        borderRadius:5,
        // backgroundColor: "rgb(218,233,188)"

    },
    resetBtn:{
        "&:hover": {
            cursor: "pointer"
        },
        height: 30,
        width: 80 ,
        color: "black",
        fontWeight:"bold",
        borderWidth:0,
        borderRadius:5,
        backgroundColor: "rgb(236,246,255)"
    },
    tableCell: {
        padding: "0 5px",
        cursor: "default",

    },
    paginationWrap: {
        background: "#fff",
        height: 45,
        display: "flex",
        justifyContent: "center",
        borderTop: "1px solid"
    },
    button: {

    },
}

export default withStyles(styles)(CustomTable);