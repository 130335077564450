import React from "react";
import {
    Container,
    TextField,
    Typography,
    Button
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { login, createAuthToken, getLMSRoleDetail } from "./../../service/api";
import Alert from "./../../components/alert";
import { getLocalStorage, saveLocalStorage } from "./../../utils/localStorage";
import { convertFormEncodedUrl } from "../../utils/helper";
import backgroundImg from "../../assets/backBg.svg"
class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            userName: "",
            password: "",
            hasError: false,
            errorMessage: null
        }
    }

    handleField = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleValidation = () => {
        let isValid = true;
        return isValid;
    }

    createAuthToken = () => {
        const { userName, password } = this.state;

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        }

        const body = {
            "username": userName,
            "password": password,
            "client_id": "abh",
            "grant_type": "password"
        }

        createAuthToken(convertFormEncodedUrl(body), headers,
            function (res) {
                console.log("received", res);
            }
        )


    }

    setLmsUserDetails = ()=>{
        const { history } = this.props;
        getLMSRoleDetail((res)=>{
            if(res && res.success){
                saveLocalStorage("LmsUserDetails", res.response);

                const result=getLocalStorage("LmsUserDetails");
                const product = getLocalStorage('product');
                const {role}= result;
                if(role[0]==="ROLE_MP_REQUEST"){
                    history.push("/home/autoUnderwriting")
                }else if(role[0]==="ROLE_ADMIN_RO"){
                    history.push("/home/user")
                }else{
                    product && product === 'ewa' ? history.push("/home/ewa") : history.push("/home/user")
                }
            }else{
                this.setState({
                    hasError: true,
                    errorMessage: res?res.error:""
                })
            }
        })
    }

    submitLogin = e => {

        e.preventDefault();

        const headers = {
            "Content-Type": "application/json"
        }

        if (this.handleValidation()) {
            const { userName, password } = this.state;
            login(
                { "username": userName, "password": password },
                headers,
                (res) => {
                    console.log("login response in module: ", res);
                    if (!res || res.hasOwnProperty("errorCode")) {
                        this.setState({
                            hasError: true,
                            errorMessage: res ? res.message : "Something went wrong or wrong email or password"
                        })
                    }
                    if (res && res.access_token) {
                        saveLocalStorage("auth", res);
                        const product = getLocalStorage('product');
                        if (!product) {
                            saveLocalStorage('product', 'loan');
                        }
                        this.setLmsUserDetails();
                    }

                }
            )
        }
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render() {
        const { classes } = this.props;
        const { userName, password, hasError, errorMessage } = this.state;
        return (
            <>

            <h1 style={{
                    right:"3%",
                    top:0,
                    position:"absolute",
                    color:"#1B2D93",
                    fontFamily: "'Signika', sans-serif"
                }}>BetterPlace Crm</h1>
            <div style={
               {
                   backgroundImage: `url(${backgroundImg})`,
                   height: "100vh",
                   display: "flex",
                   backgroundSize: 'cover',
                   overflow: 'hidden',
                //    background: "silver",
                   alignItems: "center"
                }}
               >

            <Container maxWidth="sm" >
                <form onSubmit={this.submitLogin} className={classes.rootContainer}>
                    <Typography variant="h4" align="center" className={classes.typographyLogin}>LOGIN</Typography>
                    <TextField
                        id="username"
                        label="Username"
                        name="userName"
                        required
                        value={userName}
                        onChange={this.handleField}
                        className={classes.textField}
                        error={hasError}
                    />
                    <TextField
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        required
                        value={password}
                        onChange={this.handleField}
                        className={classes.textField}
                        error={hasError}
                    />
                    <Button
                        variant="outlined"
                        type="submit"
                        disableElevation
                        className={classes.submitButton}
                        onClick={this.submitLogin}
                    >Submit</Button>
                </form>
            </Container>
            <Alert
                open={hasError}
                message={errorMessage}
                callback={this.falsifyError}
            />
        </div>
        </>);
    }
}

const styles = {
    superRootContainer: {
        height: "100vh",
        display: "flex",
        background: "silver",
        alignItems: "center"
    },
    rootContainer: {
        // width:"20vw",
        display: "flex",
        flexDirection: "column",
        borderRadius: 5,
        // width: 530,
        padding: 45,
        boxShadow: "0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2)",
        background: "#fff"
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: "80%"
    },
    submitButton: {
        width: 100,
        alignSelf: "center",
        color: "#fff",
        background: "#1B2D93",
        "&:hover": {
            background: "#1B2D93"
        }
    },
    typographyLogin: {
        fontFamily: "'Signika', sans-serif",
        margin:10
    }
}

export default withStyles(styles)(Login);