import {
    PATHS,
    API
} from "./constants";
import axios from "axios";

export function login (data, headers, cb) {
    API.GENERAL(PATHS.LOGIN, 'POST', data, {}, headers,
        function (res) {
            console.log("login response is: ", res);
            cb(res);
        }
    )
}

export function addLmsUser (data, cb) {
    API.POST(PATHS.CRM_LMS_ADD_USER, data, {},
        function (res) {
            console.log("ADD user  response is: ", res);
            cb(res);
        }
    )
}

export function getUser (params, cb) {
    API.POST(PATHS.CRM_USER, params, {},
        function (res) {
            console.log("getUser response is: ", res);
            cb(res);
        }
    )

}

export function getUserDetail (id, params, cb) {
    API.GET(PATHS.CRM_USER_DETAIL(id), {}, {},
        function (res) {
            console.log("getUserDetail response is:", res);
            cb(res)
        }
    )
}

export function getLMSRoleDetail (cb) {
    API.GET(PATHS.CRM_LMS_USER_AUTH, {}, {},
        function (res) {
            console.log("getLMSUserDetail response is:", res);
            cb(res)
        }
    )
}


export function generateLoanPaymentLink (id, params, cb) {

    const { phone, amount } = params;
    API.GET(PATHS.CRM_LOAN_PAYMENT_LINK_GENERATION(id, phone, amount), {}, {},
        function (res) {
            console.log("link loan res" + res);
            cb(res)
        }
    )

}

export function generateCreditLinePaymentLink (id, params, cb) {

    const { phone, amount } = params;
    API.GET(PATHS.CRM_CREDITLINE_PAYMENT_LINK_GENERATION(id, phone, amount), {}, {},
        function (res) {
            console.log("link cl res" + res);
            cb(res)
        }
    )

}




export function getLoan (params, cb) {
    API.POST(PATHS.CRM_LOAN, params, {},
        function (res) {
            console.log("getLoanDetail response is:", res);
            cb(res);
        }
    )
}

export function getSearch (params, cb) {
    const { searchType, searchText, type } = params;
    if (type === 'loan') {
        API.GET(PATHS.CRM_LOAN_SEARCH(type, searchType, searchText), {}, {},
            function (res) {
                console.log('loan search result=' + res);
                cb(res);
            })
    } else if (type === "ewa") {
        API.GET(PATHS.EWA.SEARCH_APPLICATIONS(searchType, searchText), {}, {},
            function (res) {
                console.log('ewa search result=' + res);
                cb(res);
            })
    } else {
        API.GET(PATHS.CRM_CREDIT_LINE_SEARCH(type, searchType, searchText), {}, {},
            function (res) {
                console.log('loan search result=' + res);
                cb(res);
            })
    }


}
export function getLoanDetail (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_DETAIL(id), {}, {},
        function (res) {
            console.log("getLoanDetail response is:", res);
            cb(res);
        }
    )

}

export function getLoanStatement (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_STATEMENT(id), {}, params,
        function (res) {
            console.log("getLoanStatement response is:", res);
            cb(res);
        }
    )
}

export function getLoanPayments (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_USER_PAYMENT(id), {}, {},
        function (res) {
            console.log(res)
            cb(res);
        }
    )
}



export function getLoanEmandate (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_EMANDATE(id), {}, {},
        function (res) {
            console.log(res)
            cb(res);
        }
    )
}

export function getAgent (cb) {
    API.GET(PATHS.CRM_AGENT, {}, {},
        function (res) {
            if (res.status === 200) {
                console.log("getAgent response is: ", res);
                cb(res);
            }
        }
    )
}

export function getCreditLine (params, cb) {
    API.POST(PATHS.CRM_CREDIT_LINE, params, {},
        function (res) {
            console.log("credit Line response is: ", res);
            cb(res);
        }
    )
}


export function getUnderWritingList (params, cb) {
    API.POST(PATHS.CRM_UNDERWRITING, params, {},
        function (res) {
            console.log("UnderWriting list " + res);
            cb(res)
        }
    )
}


export function getUnderWritingDetails (id, cb) {
    API.GET(PATHS.CRM_UNDERWRITING_DETAIL(id), {}, {},
        function (res) {
            console.log("UW details : ", res);
            cb(res);
        }
    )
}

export function patchUnderWritingDetails (id, body, cb) {
    API.PATCH(PATHS.CRM_UNDERWRITING_UPDATE_UW(id), body, {},
        function (res) {
            console.log("get LMS Patch detail res " + res);
            cb(res)
        }
    )
}

export function getCreditLineDetail (id, params, cb) {
    API.GET(PATHS.CRM_CREDIT_LINE_DETAIL(id), {}, {},
        function (res) {
            console.log("get credit line Detail response is: ", res);
            cb(res);
        }
    )
}

export function getCreditLineStatement (id, params, cb) {
    API.GET(PATHS.CRM_CREDIT_LINE_STATEMENT(id), {}, {},
        function (res) {
            console.log("get credit line statement response is: ", res);
            cb(res);
        }
    )
}

export function getCreditLineTransaction (id, params, cb) {
    API.GET(PATHS.CRM_CREDIT_LINE_TRANSACTION(id), {}, {},
        function (res) {
            console.log("get credit line transaction response is: ", res);
            cb(res);
        }
    )
}

export function getCreditLineEmandate (id, params, cb) {
    API.GET(PATHS.CRM_CREDIT_LINE_EMANDATE(id), {}, {},
        function (res) {
            console.log("get credit line emandate response is: ", res);
            cb(res);
        }
    )
}

export function createAuthToken (body, headers, cb) {
    console.log(body, headers, PATHS.AUTH);
    API.GENERAL(PATHS.AUTH, 'post', body, {}, headers,
        function (res) {
            cb(res);
        }
    )
}

export function getCreditLinePayments (id, params, cb) {
    API.GET(PATHS.CRM_CREDIT_LINE_PAYMENT(id), {}, {},
        function (res) {
            cb(res);
        }
    )
}

export function getLmsUser (params, cb) {
    API.POST(PATHS.CRM_LMS_USER, params, {},
        function (res) {
            console.log("get LMS user response is:", res);
            cb(res);
        }
    )
}

export function getLmsUserDetails (id, cb) {
    API.GET(PATHS.CRM_LMS_USER_DETAILS(id), {}, {},
        function (res) {
            console.log("get LMS user detail response is:", res);
            cb(res);
        }
    )
}

export function editLmsUser (id, params, cb) {
    API.PATCH(PATHS.CRM_LMS_EDIT_USER(id), params, {},
        function (res) {
            console.log("get LMS Patch detail res " + res);
            cb(res)
        }
    )
}

export function getUserInfo (id, params, cb) {
    API.GET(PATHS.CRM_USER_DETAIL_USER_DETAIL(id), {}, {},
        function (res) {
            console.log("get user userDetails : ", res);
            if (res) cb(res.response.userDetails);
            else cb(res);
        }
    )
}

export function getUserLoanDetail (id, params, cb) {
    API.GET(PATHS.CRM_USER_DETAIL_LOAN_DETAIL(id), {}, {},
        function (res) {
            console.log("get user loan  details: ", res);
            if (res) cb(res.response.loanDetail);
            else cb(res)
        })
}

export function getUserKycDetail (id, params, cb) {
    API.GET(PATHS.CRM_USER_DETAIL_KYC_DETAIL(id), {}, {},
        function (res) {
            console.log("get user kyc details: ", res);
            if (res) cb(res.response.kycDetail);
            else cb(res);
        })
}

export function getUserCreditlineDetail (id, params, cb) {
    API.GET(PATHS.CRM_USER_DETAIL_CREDITLINE_DETAIL(id), {}, {},
        function (res) {
            console.log("get user creditline details: ", res);
            if (res) cb(res.response.creditLineDetail);
            else cb(res);
        })
}

export function getLoanInfo (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_DETAIL_LOAN_DETAIL(id), {}, {},
        function (res) {
            console.log("get loan Details: ", res);
            if (res) cb(res.response.loanDetail);
            else cb(res)
        }
    )
}

export function getLoanUserInfo (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_DETAIL_USER_DETAIL(id), {}, {},
        function (res) {
            console.log("get loan user Details: ", res);
            if (res) cb(res.response.userDetail);
            else cb(res)
        }
    )
}

export function getWaiverInfo (id, params, cb) {
    API.GET(PATHS.CRM_WAIVER_DETAIL(id), {}, {},
        function (res) {
            console.log("get loan user Details: ", res);
            if (res) cb(res.response.waiverRequest);
            else cb(res)
        }
    )
}

export function getLoanApplicationInfo (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_DETAIL_APPLICATION_DETAIL(id), {}, {},
        function (res) {
            console.log("get loan Application Details: ", res);
            if (res) cb(res.response.loanApplicationDetail);
            else cb(res)
        }
    )
}

export function getLoanDisbursementInfo (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_DETAIL_DISBURSEMENT_DETAIL(id), {}, {},
        function (res) {
            console.log("get loan Disbursement Details: ", res);
            if (res) cb(res.response.loanDisbursementDetail)
            else cb(res)
        }
    )
}

export function getLoanBankInfo (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_DETAIL_BANK_DETAIL(id), {}, {},
        function (res) {
            console.log("get loan Bank Details: ", res);
            if (res) cb(res.response.loanBankDetail);
            else cb(res)
        }
    )
}

export function getLoanEmandateInfo (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_DETAIL_EMANDATE_DETAIL(id), {}, {},
        function (res) {
            console.log("get loan emandate Details: ", res);
            if (res) cb(res.response.loanEmandateDetail)
            else cb(res)
        }
    )
}

export function getLoanKycInfo (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_DETAIL_KYC_DETAIL(id), {}, {},
        function (res) {
            console.log("get loan kyc Details: ", res);
            if (res) cb(res.response.loanKycDetail)
            else cb(res);
        }
    )
}

export function getLoanDeductionInfo (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_DEDUCTION_DETAILS(id), {}, {},
        function (res) {
            console.log("get loan deduction Details: ", res);
            if (res) cb(res.response.loanDeductionDetail)
            else cb(res)
        }
    )
}

export function updatePaymentRecord(id, params={}, cb) {
    API.POST(PATHS.CRM_RECORD_UPDATED_PAYMENT(id), {}, params,
    function(res) {
        console.log('update payment record response...', res);
        cb(res);
    })
}

export function getLoanDigiLockerInfo (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_DIGILOCKER_DETAILS(id), {}, {},
        function (res) {
            console.log("get loan digilocker Details: ", res);
            if (res) cb(res.response)
            else cb(res)
        }
    )
}

export function getLoanCkycDetail (id, cb) {
    API.GET(PATHS.CRM_LOAN_CKYC_DOCUMENT_DETAILS(id), {}, {}, function (res) {
        console.log("get loan ckyc document details: ", res);
        if (res) cb(res.response)
        else cb(res)
    })
}

export function getLoanUpiAutoPayInfo (id, params, cb) {
    API.GET(PATHS.CRM_LOAN_UPI_AUTOPAY_DETAILS(id), {}, {},
        function (res) {
            console.log("get loan AUTOPAY Details: ", res);
            if (res) cb(res.response)
            else cb(res)
        }
    )
}

// deduction all details
export function getLoanDeductionDetails (id, cb) {
    API.GET(PATHS.CRM_DEDUCTION_DETAILS(id), {}, {},
        function (res) {
            console.log("get loan deduction all Details: ", res);
            cb(res)
        }
    )
}

// uw details
export function getUnderwritingBasicInfo (id, params, cb) {
    API.GET(PATHS.CRM_UNDERWRITING_BASIC_DETAIL(id), {}, {},
        function (res) {
            console.log("get uw basic Details: ", res);
            if (res) cb(res.response);
            else cb(res)
        }
    )
}

// uw counts
export function getUnderWritingCount( cb) {
    API.GET(PATHS.CRM_UW_COUNTS, {}, {},
        function (res) {
            cb(res);
            console.log("get uw count Details: ", res);
        }
    )
}

// uw manual check counts
export function getManualCheckCount( cb) {
    API.GET(PATHS.CRM_MANUAL_REVIEW_COUNT, {}, {},
        function (res) {
            cb(res);
            console.log("get manual count Details: ", res);
        }
    )
}



//cl details
export function getCreditLineApplicationInfo (id, params, cb) {
    API.GET(PATHS.CRM_CREDITLINE_APPLICATION_DETAIL(id), {}, {},
        function (res) {
            console.log("get cl application Details: ", res);
            if (res) cb(res.response.clApplication);
            else cb(res)
        }
    )
}

export function getCreditLineKycInfo (id, params, cb) {
    API.GET(PATHS.CRM_CREDITLINE_KYC_DETAIL(id), {}, {},
        function (res) {
            console.log("get cl kyc Details: ", res);
            if (res) cb(res.response.kycDetail)
            else cb(res)
        }
    )
}

export function getCreditLineEmandateInfo (id, params, cb) {
    API.GET(PATHS.CRM_CREDITLINE_EMANDATE_DETAIL(id), {}, {},
        function (res) {
            console.log("get cl emandate Details: ", res);
            if (res) cb(res.response.emandateDetail)
            else cb(res)
        }
    )
}


export function getCreditLineDigiLockerInfo (id, params, cb) {
    API.GET(PATHS.CRM_CREDITLINE_DIGILOCKER_DETAIL(id), {}, {},
        function (res) {
            console.log("get cl digilocker Details: ", res);
            if (res) cb(res.response.digiLockerDetail)
            else cb(res)
        }
    )
}

export function getLoanAmortSchedule (id, params, cb) {
    API.GET(PATHS.CRM_AMORT_SCHEDULE(id), {}, {},
        function (res) {
            console.log("get loan amort schedule response is:", res);
            cb(res);
        }
    )
}

/// get amort emi download
export function getLoanAmortEmiDownload (id, params, cb) {
    API.DOWNLOAD(PATHS.CRM_AMORT_EMI_DOWNLOAD(id), {}, {},
        function (res) {
            console.log("get loan amort emi  schedule response is:", res);
            cb(res);
        }
    )
}

// waivers api

export function getWaiverListing (params, cb) {
    API.POST(PATHS.CRM_WAIVER_LISTING, params, {},
        function (res) {
            console.log("get waiver Listing  response is:", res);
            cb(res);
        }
    )
}

export function postWaiverRequestLoan (params, cb) {
    API.POST(PATHS.CRM_WAIVER_GENERATION_LOAN, params, {},
        function (res) {
            console.log("post waiver loan  response is:", res);
            cb(res);
        }
    )
}

export function postWaiverRequestCreditline (params, cb) {
    API.POST(PATHS.CRM_WAIVER_GENERATION_CREDITLINE, params, {},
        function (res) {
            console.log("post waiver cl  response is:", res);
            cb(res);
        }
    )
}

export function postWaiverAcceptRejectLoan (id,params, cb) {
    API.POST(PATHS.CRM_WAIVER_ACCEPT_REJECT_LOAN(id), params, {},
        function (res) {
            console.log("get waiver loan accept or reject response is:", res);
            cb(res);
        }
    )
}

export function postWaiverAcceptRejectCreditLine (id,params, cb) {
    API.POST(PATHS.CRM_WAIVER_ACCEPT_REJECT_CREDITLINE(id), params, {},
        function (res) {
            console.log("get waiver cl accept or reject response is:", res);
            cb(res);
        }
    )
}

// promise to pay

export function postPromiseToPayLoan(params,cb){
    API.POST(PATHS.CRM_PROMISE_TO_PAY_LOAN, params, {},
        function (res) {
            console.log("get loan ptp response is:", res);
            cb(res);
        }
    )
}

export function postPromiseToPayCreditLine(params,cb){
    API.POST(PATHS.CRM_PROMISE_TO_PAY_CREDITLINE, params, {},
        function (res) {
            console.log("get cl ptp response is:", res);
            cb(res);
        }
    )
}

export function getPromiseToPayLoanListing (id, cb) {
    API.GET(PATHS.CRM_PTP_LOAN_LISTING(id), {}, {},
        function (res) {
            console.log("get loan ptp listing : ", res);
            cb(res)
        }
    )
}

export function getPromiseToPayCreditLineListing (id, cb) {
    API.GET(PATHS.CRM_PTP_CREDITLINE_LISTING(id), {}, {},
        function (res) {
            console.log("get cl ptp listing : ", res);
            cb(res)
        }
    )
}

//loan cancel api
export function LoanMarkNotInterested(params,cb){
    API.POST(PATHS.CRM_LOAN_MARK_NI, params, {},
    function (res) {
        console.log("reponse loan cancellation : ", res);
        cb(res)
    })
}

export function LoanMarkCancel(params,cb){
    API.POST(PATHS.CRM_LOAN_MARK_CANCEL(params.loanId), {}, {},
    function (res) {
        console.log("reponse loan cancellation : ", res);
        cb(res)
    })
}

// deduction apis

export function postDeductionFile(data,headers,cb){
    API.GENERAL(PATHS.CRM_DEDUCTION_PROCESS, 'POST', data, {}, headers,
        function (res) {
            console.log("post deduction response: ", res);
            cb(res);
        }
    )
}

export function postDeductionResponse(params,cb){
    API.POST(PATHS.CRM_DEDUCTION_PROCESS_DEDUCTION,params,{},
        function(res){
            console.log("post deduction response upload response : ", res);
            cb(res)
        }
    )
}

export function getDeductionResponseTransactionId(params,cb){
    API.GET(PATHS.CRM_DEDUCTION_PROCESS_DEDUCTION_TRANSACTION,{},params,
        function(res){
            console.log("get deduction response upload response : ", res);
            cb(res)
        }
    )
}


export function getDeductionListing(params, cb) {
    API.GET(PATHS.CRM_DEDUCTION_LISTING, {}, {},
        function (res) {
            console.log("get deduction listing response is: ", res);
            cb(res);
        }
    )
}

export function getDeductionSummaryWithTransactionId(params,cb){
    API.GET(PATHS.CRM_DEDUCTION_SUMMARY,{},params,
        function(res){
            console.log("get deduction summary response upload response : ", res);
            cb(res)
        }
    )
}

export function postDeductionRefreshTokenId(params,cb){
    API.POST(PATHS.CRM_DEDUCTION_REFRESH_TOKENID,{},params,
        function(res){
            console.log("refresh data by token id : ", res);
            cb(res)
        }
    )
}


// review api loan



export function postNameReviewLoanListing (params, cb) {
    API.POST(PATHS.CRM_LOAN_NAME_REVIEW_LISTING, params, {},
        function (res) {
            console.log("getLoan name review listing response is:", res);
            cb(res);
        }
    )
}


export function postNameReviewLoanFlagStatus (params, cb) {
    console.log(params)
    API.POST(PATHS.CRM_LOAN_NAME_MATCH_FLAG,  params,{},
        function (res) {
            console.log("getLoan review status response is:", res);
            cb(res);
        }
    )
}

export function postNameReviewLoanFlagCKYCStatus (params, cb) {
    console.log(params)
    API.POST(PATHS.CRM_LOAN_NAME_CKYC_MATCH_FLAG,  params,{},
        function (res) {
            console.log("getLoan review status response is:", res);
            cb(res);
        }
    )
}


export function postNameReviewLoanBankDetailReset (params, cb) {
    API.POST(PATHS.CRM_LOAN_NAME_MATCH_RESET_BANK_DETAIL,params,{},
        function (res) {
            console.log("getLoan review status response is:", res);
            cb(res);
        }
    )
}

export function postNameReviewLoanBankDetailResetWithMandate (params, cb) {
    API.POST(PATHS.CRM_LOAN_NAME_MATCH_RESET_BANK_DETAIL_WITH_MANDATE,params,{},
        function (res) {
            console.log("getLoan review status with emandate response is:", res);
            cb(res);
        }
    )
}

export function getNameReviewLoanDetails(id, cb) {
    API.GET(PATHS.CRM_LOAN_NAME_MATCH_DETAIL(id), {}, {},
        function (res) {
            console.log("get loan name match detail is: ", res);
            cb(res);
        }
    )
}



// company list
export function getCompanyList(cb){
    API.GET(PATHS.CRM_COMPANY_LIST, {}, {},
    function (res) {
        console.log("get company listing : ", res);
        cb(res)
    }
   )
}
// company list  for graph
export function getCompanyListForGraph(cb){
    API.GET(PATHS.CRM_COMPANY_LIST_FOR_GRAPH, {}, {},
    function (res) {
        console.log("get company listing for graph: ", res);
        cb(res)
    }
   )
}

// download statement

export function downloadLoanStatementPdf (id, cb) {
    API.DOWNLOAD(PATHS.CRM_LOAN_STATEMENT_PDF(id), {}, {},
        function (res) {
            console.log("Downloading loan statement ", res);
            cb(res);
        })
}
// agreement loan
export function downloadLoanAgreementPdf (id, cb) {
    API.DOWNLOAD(PATHS.CRM_LOAN_AGREEMENT_PDF(id), {}, {},
        function (res) {
            console.log("Downloading loan agreement ", res);
            cb(res);
        })
}

export function downloadCreditLineStatementPdf (id, cb) {
    API.DOWNLOAD(PATHS.CRM_CREDITLINE_STATEMENT_PDF(id), {}, {},
        function (res) {
            console.log("Downloading cl statement ", res);
            cb(res);
        })
}

// loan settlement apis

export function raiseLoanSettlement(params,cb){
    API.POST(PATHS.CRM_RAISE_SETTLEMENT_LOAN, {},params,
        function (res) {
            cb(res);
            console.log("raise settlement ", res);
        }
    )
}

export function getPendingLoanSettlement (params,cb) {
    API.GET(PATHS.CRM_PENDING_LOAN_SATTLEMENT_LISTING, {}, params,
        function (res) {
            cb(res);
            console.log(" settlement listing ", res);
        })
}

export function actionLoanSettlement(params,cb){
    API.POST(PATHS.CRM_ACTION_SETTLEMENT_LOAN, {}, params,
        function (res) {
            cb(res);
            console.log(" settlement action ", res);
        }
    )
}


export function getLoanSettlementSummary (id,cb) {
    API.GET(PATHS.CRM_LOAN_SETTLEMENT_SUMMARY(id), {}, {},
        function (res) {
            cb(res);
            console.log(" settlement summary", res);
        })
}

// all payments

export function getAllLoanPayments (id, params, cb) {
    API.GET(PATHS.CRM_ALL_LOAN_PAYMENT(id), {}, {},
        function (res) {
            console.log(res)
            cb(res);
        }
    )
}

export function getAllCreditlinePayments (id, params, cb) {
    API.GET(PATHS.CRM_ALL_CREDITLINE_PAYMENT(id), {}, {},
        function (res) {
            console.log(res)
            cb(res);
        }
    )
}


// stat result
export function getLMSUserStat (cb) {
    API.GET(PATHS.CRM_USER_STAT, {}, {},
        function (res) {
            console.log("getLMSUser stat response is:", res);
            cb(res)
        }
    )
}

export function getLMSCreditLineUserStat (cb) {
    API.GET(PATHS.CRM_USER_CL_STAT, {}, {},
        function (res) {
            console.log("getLMSUser cl  stat response is:", res);
            cb(res)
        }
    )
}

// campaign apis

export function getCampaignEventList(cb){
    API.GET(PATHS.CRM_LOAN_CAMPAIGN_EVENT_LIST,  {}, {},
        function (res) {
            // console.log("compaign event list res" + res);
            cb(res)
        }
    )
}

export function getCampaignEventDetails(params,cb){
    API.GET(PATHS.CRM_LOAN_CAMPAIGN_EVENT_DETAILS(params), {}, {},
        function (res) {
            console.log("compaign event DETAIL" + res);
            cb(res)
        }
    )
}

export function loanCampaignProcessExcel(params,data,headers,cb){
    API.GENERAL(PATHS.CRM_LOAN_CAMPAIGN_PROCESS_EXCEL(params), 'POST', data, {}, headers,
        function (res) {
            console.log("loan campaign process excel ", res);
            cb(res);
        }
    )
}

// campaign cl
export function getCLCampaignEventList(cb){
    API.GET(PATHS.CRM_CL_CAMPAIGN_EVENT_LIST,  {}, {},
        function (res) {
            // console.log("compaign event list res" + res);
            cb(res)
        }
    )
}

export function getCLCampaignEventDetails(params,cb){
    API.GET(PATHS.CRM_CL_CAMPAIGN_EVENT_DETAILS(params), {}, {},
        function (res) {
            console.log("compaign event DETAIL" + res);
            cb(res)
        }
    )
}


export function clCampaignProcessExcel(params,data,headers,cb){
    API.GENERAL(PATHS.CRM_CREDITLINE_CAMPAIGN_PROCESS_EXCEL(params), 'POST', data, {}, headers,
        function (res) {
            console.log("cl campaign process excel ", res);
            cb(res);
        }
    )
}

// DOWNLOAD PTP excel loan
export function downloadLoanPtpExcel(params,cb){
    API.DOWNLOADPOST(PATHS.CRM_DOWNLOAD_LOAN_PTP, params, {},
        function (res) {
            cb(res);
            console.log(" l PTP download ", res);
        }
    )
}

// DOWNLOAD PTP excel cl
export function downloadClPtpExcel(params,cb){
    API.DOWNLOADPOST(PATHS.CRM_DOWNLOAD_CREDITLINE_PTP, params, {},
        function (res) {
            cb(res);
            console.log("cl PTP download ", res);
        }
    )
}

// loan ptp details by ptp id
export function getLoanPtpDetail(id,cb){
    API.GET(PATHS.CRM_LOAN_PTP_DETAIL(id), {}, {},
        function (res) {
            console.log("loan ptp detail" + res);
            cb(res)
        }
    )
}

// cl ptp details by ptp id
export function getCLPtpDetail(id,cb){
    API.GET(PATHS.CRM_CREDITLINE_PTP_DETAIL(id), {}, {},
        function (res) {
            console.log("loan ptp detail" + res);
            cb(res)
        }
    )
}


// PTP LISTING
export function getLoanPtpListing(params,cb){
    API.POST(PATHS.CRM_LOAN_PTP_LIST,  params,{},
        function (res) {
            console.log("loan ptp listing " + res);
            cb(res)
        }
    )
}

export function getClPtpListing(params,cb){
    API.POST(PATHS.CRM_CL_PTP_LIST,  params,{},
        function (res) {
            console.log("cl ptp listing " + res);
            cb(res)
        }
    )
}

// PTP AGENT LIST
export function getLoanPtpAgentList(cb){
    API.GET(PATHS.CRM_LOAN_PTP_AGENT_LIST, {}, {},
        function (res) {
            console.log("l ptp agent list " , res)
            cb(res)
        }
    )
}

export function getClPtpAgentList(cb){
    API.GET(PATHS.CRM_CL_PTP_AGENT_LIST, {}, {},
        function (res) {
            console.log("cl ptp agent list " , res)
            cb(res)
        }
    )
}

/// pay out file


export function postPayoutFile(data,headers,cb){
    API.GENERAL(PATHS.CRM_MP_FILE_PROCESS, 'POST', data, {}, headers,
        function (res) {
            console.log("post payout response: ", res);
            cb(res);
        }
    )
}

export function getPayoutResponseWithProcessId(id,cb){
    API.GET(PATHS.CRM_MP_FILE_PROCESS_PROCESS_ID(id), {},{},
        function (res) {
            cb(res);
            console.log("res payout response with process id  ", res);
        }
    )
}


export function getPayoutPrevList(params,cb){
    API.GET(PATHS.CRM_MP_FILE_PREV_LIST, {},params,
        function (res) {
            cb(res);
            console.log("res payout response list ", res);
        }
    )
}

/// graph for dashbourd loan

export function postCountForCompany(params,cb){
    API.POST(PATHS.CRM_GRAPH_COUNT_FOR_COMPANY, params,{},
        function (res) {
            cb(res);
            console.log("res for graph ", res);
        }
    )
}

export function postCountForCompanyByStatus(params,cb){
    API.POST(PATHS.CRM_GRAPH_COUNT_FOR_COMPANY_BY_STATUS, params,{},
        function (res) {
            cb(res);
            console.log("res for graph ", res);
        }
    )
}

export function postDpdCountForLoanPieChart(params,cb){
    API.POST(PATHS.CRM_GRAPH_COUNT_DPD_COUNT_PIE_CHART, params,{},
        function (res) {
            cb(res);
            console.log("res for graph ", res);
        }
    )
}

export function postCountsForLoan(params,cb){
    API.POST(PATHS.CRM_COUNTS_FOR_LOAN, params,{},
        function (res) {
            cb(res);
            console.log("res counts loan ", res);
        }
    )
}

export function postCountsForLoanOthers(params,cb){
    API.POST(PATHS.CRM_COUNTS_FOR_LOAN_OTHERS, params,{},
        function (res) {
            cb(res);
            console.log("res counts loan others", res);
        }
    )
}


export function postLoanDashboardUsersInKycSteps(params,cb){
    API.GET(PATHS.CRM_LOAN_USERS_IN_KYC_STEPS,{}, params,
        function (res) {
            cb(res);
            console.log("res kycinsteps", res);
        }
    )
}
export function postLoanDashboardAmountDisbursedGraph(params,cb){
    API.POST(PATHS.CRM_LOAN_AMOUNT_DISBURSED_GRAPH, params,{},
        function (res) {
            cb(res);
            console.log("res amount disubersed graph", res);
        }
    )
}
export function postLoanDashboardPaymentCollectionGraph(params,cb){
    API.POST(PATHS.CRM_LOAN_PAYMENT_COLLECTION_GRAPH, params,{},
        function (res) {
            cb(res);
            console.log("res payment collection graph", res);
        }
    )
}


/// graph for dashbourd cl

export function postClCountForCompany(params,cb){
    API.POST(PATHS.CRM_CL_GRAPH_COUNT_FOR_COMPANY, params,{},
        function (res) {
            cb(res);
            console.log("res for graph ", res);
        }
    )
}

export function postClCountForCompanyByStatus(params,cb){
    API.POST(PATHS.CRM_CL_GRAPH_COUNT_FOR_COMPANY_BY_STATUS, params,{},
        function (res) {
            cb(res);
            console.log("res for graph ", res);
        }
    )
}

export function postDpdCountForclPieChart(params,cb){
    API.POST(PATHS.CRM_CL_GRAPH_COUNT_DPD_COUNT_PIE_CHART, params,{},
        function (res) {
            cb(res);
            console.log("res for graph ", res);
        }
    )
}

export function postCountsForcl(params,cb){
    API.POST(PATHS.CRM_CL_COUNTS_FOR_LOAN, params,{},
        function (res) {
            cb(res);
            console.log("res counts loan ", res);
        }
    )
}

export function getClEmandateCountsForCards(params,cb){
    API.GET(PATHS.CRM_CL_COUNT_EMANDATE_CARDS, {},params,
        function (res) {
            cb(res);
            console.log("res emandate loan cards count  ", res);
        }
    )
}
// PAYMENT PROCESSING CRON

export function getPaymentCron(cb){
    API.GET(PATHS.CRM_CRON_PAYMENT_PROCESSING, {}, {},
        function (res) {
            console.log("cron res " , res)
            cb(res)
        }
    )
}

// bank kyc detail reset

export function postNameReviewLoanResetLoanKycDetail (id, cb) {
    API.POST(PATHS.CRM_LOAN_KYC_DETAIL_RESET(id),{},{},
        function (res) {
            console.log("Kyc detail reset loan:", res);
            cb(res);
        }
    )
}

// collection functions
export function getCollection (params, cb) {
    axios.post('https://1b7699de-69bf-498b-8179-4bfe708b1196.mock.pstmn.io/api/crm/collection', {
        headers: {
            'Authorization': 'Bearer MjU0MjgxNTgwMTIzMjY0MjQ4'
        },
        params: params
    }).then(res => {
        console.log('data for collection ' + res);
        cb(res.data);
    }).catch(err => {
        console.log("ERROR: ", err);
    })
}


export function getCall_Logs (id, params, cb) {
    API.GET(PATHS.CRM_USER_CALLLOG(id),
        function (res) {
            console.log("getCall Log response is:", res);
            cb(res);
        })
}
export function getPayments (id, params, cb) {

}
export function getStatement (id, params, cb) {

}
