import { convertTimestamptoDate } from "../../utils/helper";

export const DATA = [
  { key: "application-id", align: "center", label: "APPLICATION ID", value: "applicationId", type: "text", sort: true, fn: (v) => v },
  { key: "application-status", align: "center", label: "APPLICATION STATUS", value: "applicationStatus", type: "text", sort: false, fn: (v) => v },
  { key: "line-status", align: "center", label: "LINE STATUS", value: "lineStatus", type: "text", sort: false, fn: (v) => v },
  { key: "reason-code", align: "center", label: "REASON CODE", value: "reasonCode", type: "text", sort: true, fn: (v) => v ?? "-" },
  { key: "dpd-count", align: "center", label: "DPD COUNT", value: "dpdCount", type: "text", sort: true, fn: (v) => v },
  { key: "current-limit", align: "center", label: "CURRENT LIMIT", value: "currentLimit", type: "text", sort: true, fn: (v) => v ?? '-' },
  { key: "application-completion-date", align: "center", label: "APPLICATION COMPLETION DATE", value: "applicationCompletedOn", type: "text", sort: true, fn: (v) => convertTimestamptoDate(v) },
  { key: "outstanding-amount", align: "center", label: "OUTSTANDING AMOUNT", value: "dueAmount", type: "text", sort: true, fn: (v) => v },
]