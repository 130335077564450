import React from "react";
import {
    Menu,
    MenuItem,
    IconButton,
    Grid,
    Button,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CustomModal from './modal';
import CloseIcon from '@material-ui/icons/Close';
import { getLocalStorage, saveLocalStorage } from "../utils/localStorage";
import { downloadClPtpExcel, downloadLoanPtpExcel, getLMSCreditLineUserStat, getLMSUserStat, getPaymentCron } from "../service/api";
import BPLogo from "../assets/betterplace_logo.png"
import Alert from "./alert";
import { Colors } from "../styles";
import "../screens/dashboard/main.css";


const accessIds = [
    "admin@gmail.com",
    "crmadmin@buenofinance.in",
    "ghanshyam.soni@buenofinance.in",
    "swaraj.singh@buenofinance.in",
    "kandy@gmail.com",
    "tm.crm@buenofinanceinc.com"
]

class AppBar extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            isMenuOpen: false,
            anchorEl: null,
            collectionThisMonth: 0,
            collectionToday: 0,
            collectionCLThisMonth: 0,
            collectionCLToday: 0,
            showIt: false,
            product: 'loan'
        }
    }

    componentDidMount () {}

    handleLogOut = () => {
        localStorage.clear();
        this.props.history.push("/login")
    }

    openMenuBar = evt => {
        this.setState({
            anchorEl: evt.currentTarget
        })
    }

    closeMenuBar = _ => {
        this.setState({
            anchorEl: null
        })
    }

    render () {
        const { classes } = this.props;
        const { isMenuOpen, anchorEl, collectionThisMonth, collectionToday, collectionCLThisMonth,
            collectionCLToday, showIt } = this.state;
        return (
            <div className={classes.appBarContainer}>
                <h2 style={{
                    color: Colors.primary,
                    position: "absolute",
                    left: "0%",
                    display: "flex",
                    alignContent: "space-between",
                }}>
                    <img src={BPLogo} style={{ width: "220px" }} alt="betterplace_logo" />
                </h2>

                <div className={classes.theOtherContainer}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        flexWrap: 'wrap',
                        marginRight: "1%",
                        color: Colors.primary,
                    }}>
                        <MenuBar
                            isMenuOpen={isMenuOpen}
                            collectionToday={collectionToday}
                            collectionThisMonth={collectionThisMonth}
                            collectionCLToday={collectionCLToday}
                            collectionCLThisMonth={collectionCLThisMonth}
                            showIt={showIt}
                            classes={classes}
                            openMenuBar={this.openMenuBar}
                            closeMenuBar={this.closeMenuBar}
                            handleLogOut={this.handleLogOut}
                            anchorEl={anchorEl} />
                    </div>
                </div>
            </div>
        );
    }
}


function ViewProfile (props) {
    const { handleClose, collectionToday, collectionThisMonth, collectionCLToday,
        collectionCLThisMonth, showIt } = props;
    const res = getLocalStorage("LmsUserDetails");
    const { email, name, phone } = res;
    return (
        <>
            <div style={styles.backContainer}>
                <IconButton variant="contained"
                    style={styles.closeBtn}
                    onClick={() => handleClose()}
                ><CloseIcon /></IconButton>
                <Typography
                    variant="h5"
                    gutterBottom
                    style={styles.typography}
                >
                    <div >{name}</div>
                    <div style={{ fontWeight: 500, color: "black" }}>{email}</div>
                    <div style={{ fontWeight: 500, color: "black" }}>{phone}</div>
                </Typography>
                {showIt ?
                    <>
                        <div style={{ margin: 10, padding: 10, display: "grid", gridTemplateColumns: "auto auto auto" }}>
                            <div style={{ margin: 10, padding: 10, background: "#35495F", borderRadius: 10, color: "white" }}>
                                <div style={{ fontSize: 20, fontWeight: "bold" }}>Loan Collection</div>
                                <div>COLLECTION TODAY :₹ {collectionToday}</div>
                                <div>COLLECTION THIS MONTH :₹ {collectionThisMonth}</div>
                            </div>
                            {/* <div style={{margin:10,padding:10,background:"rgb(21 27 128 )",borderRadius:10,color:"white"}}>
                            <div style={{fontSize:20,fontWeight:"bold"}}>Creditline Collection</div>
                            <div>COLLECTION TODAY :₹ {collectionCLToday }</div>
                            <div>COLLECTION THIS MONTH :₹ {collectionCLThisMonth}</div>
                        </div> */}
                            <div style={{ margin: 10, padding: 10, background: "#e6f5e6", borderRadius: 10 }}>
                                <div style={{ fontSize: 20, fontWeight: "bold" }}>Total Collection</div>
                                <div>COLLECTION TODAY :₹ {collectionToday + collectionCLToday}</div>
                                <div>COLLECTION THIS MONTH :₹ {collectionThisMonth + collectionCLThisMonth}</div>
                            </div>
                        </div></> : null}
            </div>
        </>
    )
}


class DownloadPtpExcel extends React.Component {
    constructor () {
        super();
        this.state = {
            hasError: "",
            errorType: "",
            errorMessage: "",
            fromDate: "",
            toDate: "",
            errors: {},
            eventType: "loanType",
        }
    }

    componentDidMount () {
    }


    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }


    handleField = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        this.setState({
            [name]: value
        })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;
        const { fromDate, toDate } = this.state;

        if (!fromDate) {
            formIsValid = false;
            errorsTemp["fromDate"] = "Please mention from date";
        }

        if (!toDate) {
            formIsValid = false;
            errorsTemp["toDate"] = "Please mention to date";
        }


        this.setState({ errors: errorsTemp });
        return formIsValid;
    }



    setToDefault = () => {
        this.setState({
            waiveOffAmount: "",
            promiseAmount: "",
            typeOfFee: "",
            reason: "",
            errors: {}
        })
    }

    downloadExcel = e => {
        const { fromDate, toDate, eventType } = this.state;
        let finalDetails = {
            "from": fromDate,
            "to": toDate
        }
        e.preventDefault();
        if (this.handleValidation()) {
            if (eventType === "loanType") {
                downloadLoanPtpExcel(finalDetails, (res) => {
                    if (res) {
                        this.setToDefault()
                    } else {
                        this.setState({
                            hasError: true,
                            errorMessage: "",
                            errorType: "error"
                        })
                    }
                })
            } else {
                downloadClPtpExcel(finalDetails, (res) => {
                    if (res) {
                        this.setToDefault()
                    } else {
                        this.setState({
                            hasError: true,
                            errorMessage: "",
                            errorType: "error"
                        })
                    }
                })
            }
        } else {
            this.setState({
                hasError: true,
                errorMessage: "Please fill the details correctly ",
                errorType: "error"
            })
        }
    }



    loadModule = () => {
        const comps = [];
        const { promiseDate, errors } = this.state;
        const dispatchfields = [
            { id: "fromDate", type: "date", name: "promiseDate", label: "FROM", value: promiseDate, isSelect: false, option: [], isDisable: false, isMultiline: false },
            { id: "toDate", type: "date", name: "promiseDate", label: "TO", value: promiseDate, isSelect: false, option: [], isDisable: false, isMultiline: false },
        ]
        for (let obj of dispatchfields) {
            const { id, type, label, value, multiline, isSelect, isDisable, option } = obj;
            let TextFieldProps = {
                id: id,
                key: id,
                disable: isDisable,
                multiline: multiline,
                name: id,
                select: isSelect,
                label: label,
                type: type,
                value: value,
                defaultValue: isSelect ? option[0] : "",
                isSelect: isSelect,
                option: option,
                helperText: errors[id]
            }
            comps.push(TextFieldProps);
        } return comps;
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    };

    render () {
        const { handleClose } = this.props;
        const { hasError, errorMessage, errorType, minDate, maxDate, eventType } = this.state;
        const comps = this.loadModule();
        return (
            <>
                <div style={styles.rootContainerPtp}>
                    <IconButton variant="contained"
                        style={styles.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        style={styles.typography}
                    >DOWNLOAD EXCEL PTP</Typography>

                    <Grid container direction="column" justiftContent="flex-start" style={styles.container}>
                        <div style={{ margin: 15 }}>
                            <RadioGroup row aria-label="type" name="eventType" value={eventType} onChange={this.handleChange}>
                                <FormControlLabel value="loanType" control={<Radio style={{ color: "green" }} />} label="Loan" />
                                <FormControlLabel value="clType" control={<Radio style={{ color: "green" }} />} label="Creditline" />
                            </RadioGroup>
                        </div>
                        <Grid container item xs={12} spacing={1}>
                            {comps.map(comp => {
                                const { id, name, type, isSelect, label, option, value, helperText, isDisable } = comp;
                                return <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>{label}</Typography>
                                    <TextField
                                        id={id}
                                        key={id}
                                        name={name}
                                        type={type}
                                        select={isSelect}
                                        value={value}
                                        defaultValue={isSelect ? option[0] : null}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        disable={isDisable}
                                        helperText={helperText}
                                        InputProps={{ inputProps: { min: minDate, max: maxDate } }}
                                        onChange={(evt) => this.handleField(evt, {
                                            label: label
                                        })}
                                        SelectProps={{
                                            MenuProps: {
                                                MenuListProps: {
                                                    style: {
                                                        maxHeight: 210
                                                    }
                                                }
                                            }
                                        }}

                                        FormHelperTextProps={{
                                            style: {
                                                color: "red",
                                                fontSize: 12,
                                                marginLeft: "-1px",

                                            }
                                        }}
                                        style={styles.textField}
                                        size={'small'}
                                        variant="outlined"
                                    >{isSelect ? this.loadOptions(option) : null}</TextField>
                                </Grid>
                            })}

                            <Grid item xs={3} style={styles.fieldWrapper}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    disableFocusRipple
                                    style={styles.addButton}
                                    onClick={this.downloadExcel}
                                >DOWNLOAD</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    type={errorType}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

class PaymentCron extends React.Component {
    constructor () {
        super();
        this.state = {
            hasError: "",
            errorType: "",
            errorMessage: "",
            cronPassword: "",
            errors: {},
            eventType: "loanType",
        }
    }

    componentDidMount () {
    }


    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }


    handleField = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        this.setState({
            [name]: value
        })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;
        const { cronPassword } = this.state;

        if (!cronPassword) {
            formIsValid = false;
            errorsTemp["cronPassword"] = "Please enter password";
        } else if (cronPassword !== "BuenoKandy321#") {
            formIsValid = false;
            errorsTemp["cronPassword"] = "Please enter correct password ";
        }

        this.setState({ errors: errorsTemp });
        return formIsValid;
    }



    setToDefault = () => {
        this.setState({
            waiveOffAmount: "",
            promiseAmount: "",
            typeOfFee: "",
            reason: "",
            errors: {}
        })
    }

    RunCron = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            getPaymentCron((res) => {
                if (res) {
                    this.setToDefault()
                } else {
                    this.setState({
                        hasError: true,
                        errorMessage: "",
                        errorType: "error"
                    })
                }
            })
        } else {
            this.setState({
                hasError: true,
                errorMessage: "password do not match to run cron  ",
                errorType: "error"
            })
        }
    }



    loadModule = () => {
        const comps = [];
        const { promiseDate, errors } = this.state;
        const dispatchfields = [
            { id: "cronPassword", type: "password", name: "cronPassword", label: "CRON PASSWORD", value: promiseDate, isSelect: false, option: [], isDisable: false, isMultiline: false },
        ]
        for (let obj of dispatchfields) {
            const { id, type, label, value, multiline, isSelect, isDisable, option } = obj;
            let TextFieldProps = {
                id: id,
                key: id,
                disable: isDisable,
                multiline: multiline,
                name: id,
                select: isSelect,
                label: label,
                type: type,
                value: value,
                defaultValue: isSelect ? option[0] : "",
                isSelect: isSelect,
                option: option,
                helperText: errors[id]
            }
            comps.push(TextFieldProps);
        } return comps;
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    };

    render () {
        const { handleClose } = this.props;
        const { hasError, errorMessage, errorType, minDate, maxDate } = this.state;
        const comps = this.loadModule();
        return (
            <>
                <div style={styles.rootContainerPtp}>
                    <IconButton variant="contained"
                        style={styles.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        style={styles.typography}
                    >PAYMENT CRON</Typography>

                    <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                        <Grid container item xs={12} spacing={1}>
                            {comps.map(comp => {
                                const { id, name, type, isSelect, label, option, value, helperText, isDisable } = comp;
                                return <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>{label}</Typography>
                                    <TextField
                                        id={id}
                                        key={id}
                                        name={name}
                                        type={type}
                                        select={isSelect}
                                        value={value}
                                        defaultValue={isSelect ? option[0] : null}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        disable={isDisable}
                                        helperText={helperText}
                                        InputProps={{ inputProps: { min: minDate, max: maxDate } }}
                                        onChange={(evt) => this.handleField(evt, {
                                            label: label
                                        })}
                                        SelectProps={{
                                            MenuProps: {
                                                MenuListProps: {
                                                    style: {
                                                        maxHeight: 210
                                                    }
                                                }
                                            }
                                        }}

                                        FormHelperTextProps={{
                                            style: {
                                                color: "red",
                                                fontSize: 12,
                                                marginLeft: "-1px",

                                            }
                                        }}
                                        style={styles.textField}
                                        size={'small'}
                                        variant="outlined"
                                    >{isSelect ? this.loadOptions(option) : null}</TextField>
                                </Grid>
                            })}

                            <Grid item xs={3} style={styles.fieldWrapper}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    disableFocusRipple
                                    style={styles.addButton}
                                    onClick={this.RunCron}
                                >RUN CRON</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    type={errorType}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}


function MenuBar (props) {
    const { classes, openMenuBar, closeMenuBar, anchorEl, handleLogOut, collectionToday, collectionThisMonth,
        collectionCLThisMonth, collectionCLToday, showIt } = props;
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalChildren, setModalChildren] = React.useState(null);
    const res = getLocalStorage("LmsUserDetails");
    const { email, name } = res;
    const handleModal = () => {
        if (!modalOpen) setModalOpen(true);
        else setModalOpen(false);
    }

    const setCurrentModalView = view => {
        switch (view) {
            case "ViewProfile":
                setModalChildren(<ViewProfile
                    collectionToday={collectionToday}
                    collectionThisMonth={collectionThisMonth}
                    collectionCLToday={collectionCLToday}
                    collectionCLThisMonth={collectionCLThisMonth}
                    showIt={showIt}
                    handleClose={() => setModalOpen(false)} />)
                break;
            case "DownloadPtpExcel":
                setModalChildren(<DownloadPtpExcel
                    collectionToday={collectionToday}
                    collectionThisMonth={collectionThisMonth}
                    collectionCLToday={collectionCLToday}
                    collectionCLThisMonth={collectionCLThisMonth}
                    showIt={showIt}
                    handleClose={() => setModalOpen(false)} />)
                break;

            case "PaymentCron":
                setModalChildren(<PaymentCron
                    collectionToday={collectionToday}
                    collectionThisMonth={collectionThisMonth}
                    collectionCLToday={collectionCLToday}
                    collectionCLThisMonth={collectionCLThisMonth}
                    showIt={showIt}
                    handleClose={() => setModalOpen(false)} />)
                break;
            default:
                setModalChildren(<div>Not found</div>)
        }
        handleModal();
    }


    return (
        <div className={classes.menuContainer}>
            <div className="css-1pv7ovc">
                <div className="MuiAvatar-root MuiAvatar-circular css-inlwhr">
                    <IconButton variant="contained"
                        aria-controls="appBar-menu"
                        aria-haspopup="true"
                        aria-label="MENU"
                        onClick={openMenuBar}
                        className="MuiAvatar-img css-1hy9t21"
                    ><AccountCircleIcon fontSize={"large"} /></IconButton>
                    <Menu
                        id="appBar-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeMenuBar}
                    >
                        <MenuItem className={classes.menuItem} onClick={() => setCurrentModalView("ViewProfile")}>Profile</MenuItem>
                        <MenuItem className={classes.menuItem} onClick={handleLogOut}>Logout</MenuItem>
                        {accessIds.includes(email) ?
                            <>
                                <MenuItem className={classes.menuItem} onClick={() => setCurrentModalView("DownloadPtpExcel")}>Download PTP</MenuItem>
                                <MenuItem className={classes.menuItem} onClick={() => setCurrentModalView("PaymentCron")}>Payment Cron</MenuItem>
                            </>
                            : null}
                    </Menu>
                </div>
                <div className="MuiBox-root css-k7os9j">
                    <h6 className="MuiTypography-root MuiTypography-subtitle2 MuiTypography-noWrap css-1k96qjc">
                        {name}
                    </h6>
                    <p className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap css-10n697b">
                        {email}
                    </p>
                </div>

            </div>


            <CustomModal
                isOpen={modalOpen}
                handleClose={handleModal}
            >{<>
                {modalChildren}
            </>}</CustomModal>
        </div>
    );
}

const styles = {
    appBarContainer: {
        height: 90,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        background: "#fff"
    },
    collectionDetail: {
        margin: 10
    },
    typographyContainer: {
        marginLeft: 20
    },
    theOtherContainer: {
        marginLeft: "auto",
        marginRight: 20
    },

    menuContainer: {

    },
    menuItem: {
        width: 200
    },
    backContainer: {
        // height: "90vh",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // padding:10,
        // margin:"50px 20px 20px 20px"
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll",
        // height:"80vh"
    },
    rootContainer: {
        height: "auto",
        display: "inherit",
        alignItems: "center",
        flexDirection: "column",
        width: 315,
        borderRadius: 6,
        background: "#fff",
        position: "relative",
        padding: 10
    },


    rootContainerPtp: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll",
    },
    typography: {
        fontFamily: "Poppins",
        margin: "25px 25px 20px 40px",
        color: Colors.primaryColor01,
        fontWeight: "bold"
    },
    closeBtn: {
        float: "right"
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    container: {
        padding: "0 60px"
    },
    fieldLabel: {
        fontWeight: "bold",
        fontSize: 18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
    },
    addButton: {
        width: 120,
        height: 40,
        margin: "25px 0px 0px 60px",
        color: "#fff",
        background: "rgb(21, 27, 128)",
        "&:hover": {
            background: "rgb(21, 27, 128)"
        }
    }

}

export default withStyles(styles)(AppBar);