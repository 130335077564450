import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from "react-router-dom";

import UserDetail from "./../screens/user/userDetail";
import LoanDetail from "./../screens/loan/loanDetail";
import CreditLineDetail from './../screens/creditLine/creditLineDetail'
import EditRole from "./../screens/role/editRole";
import UnderWritingEditScreen from "../screens/underwriting/uwEdit";
import { getLocalStorage } from "../utils/localStorage";
import WaiverDetail from "../screens/waiver/waiverDetail";
import PageNotFound from "../common/pageNotFound";
import ManualCheckDetail from "../screens/underwriting/manualChechDetail";
import EWADetail from "../screens/ewa/ewa-detail";

const ROLE_VALUES = {
    "ADMIN": ["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "USERS": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "LOANS": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "EWA": ["ROLE_ADMIN", "ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "WAIVERS": ["ROLE_ADMIN", "ROLE_WAIVER", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "MANUALCHECK": ["ROLE_ADMIN", "ROLE_UW", "ROLE_CRM_ADMIN", "ROLE_MRRO", "ROLE_MRRW", "ROLE_ADMIN_RO"],
    "UNDERWRITING": ["ROLE_ADMIN", "ROLE_UW", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "SETTLEMENT": ["ROLE_ADMIN", "ROLE_WAIVER", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "CAMPAIGNS": ["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_CAMPAIGN", "ROLE_ADMIN_RO"],
    "DEDUCTION": ["ROLE_ADMIN", "ROLE_DP", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "LOANPTP": ["ROLE_ADMIN", "ROLE_CE", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "EDITROLE": ["ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"],
    "ADDROLE": ["ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"]
}

function RestrictedPage (props) {
    return (<>
        <div
            style={{
                margin: "210px 540px 400px 540px",
                fontSize: 40,
                color: "red"
            }}
        >
            You are restricted to visit this page
        </div>
        {
            setTimeout(() => {
                const { history } = props;
                history.goBack();
            }, 1000)
        }
    </>)
}

function DetailRoute () {
    const res = getLocalStorage("LmsUserDetails");
    const { role } = res;
    const checkPermission = (PERMISSIONS) => {
        let conditionCheck = false;
        for (let item of PERMISSIONS) {
            if (role.includes(item)) {
                conditionCheck = true;
                break;
            }
        }

        return conditionCheck;
    }

    return (<>
        <Router >
            <Switch >

                {/* <Route exact path="/home/user/:id" component={[0,1,2,3,4,5,7].includes(data)?UserDetail:RestrictedPage} />
                <Route exact path="/home/loan/:id" component={[0,1,2,3,4,5,7].includes(data)?LoanDetail:RestrictedPage} />
                <Route exact path="/home/creditLine/:id" component={[0,1,2,3,4,5,7].includes(data)?CreditLineDetail:RestrictedPage} />
                <Route exact path="/home/underWriting/:id" component={[0,1,7].includes(data)?UnderWritingEditScreen:RestrictedPage} />

                <Route exact path="/home/manualCheck/:id" component={[0,1,7].includes(data)?ManualCheckDetail:RestrictedPage} />

                <Route exact path="/home/role/editRole/:id" component={[7].includes(data)?EditRole:RestrictedPage} />
                <Route exact path="/home/waivers/:id" component={[0,2,7].includes(data)?WaiverDetail:RestrictedPage}  />
                <Route path="/restrictedPage" component={RestrictedPage} />
                <Route path="/pageNotFound" component={PageNotFound} />
                <Route path="*"  >
                    <Redirect to="/pageNotFound" />
                </Route>  */}
                <Route exact path="/home/user/:id" component={checkPermission(ROLE_VALUES.USERS) ? UserDetail : RestrictedPage} />

                <Route exact path="/home/user/:id" component={checkPermission(ROLE_VALUES.USERS) ? UserDetail : RestrictedPage} />
                <Route exact path="/home/loan/:id" component={checkPermission(ROLE_VALUES.LOANS) ? LoanDetail : RestrictedPage} />
                <Route exact path="/home/ewa/:id" component={checkPermission(ROLE_VALUES.EWA) ? EWADetail : RestrictedPage} />
                {/* <Route exact path="/home/creditLine/:id" component={checkPermission(ROLE_VALUES.CREDITLINES) ? CreditLineDetail : RestrictedPage} /> */}
                <Route exact path="/home/underWriting/:id" component={checkPermission(ROLE_VALUES.UNDERWRITING) ? UnderWritingEditScreen : RestrictedPage} />

                <Route exact path="/home/manualCheck/:id" component={checkPermission(ROLE_VALUES.MANUALCHECK) ? ManualCheckDetail : RestrictedPage} />

                <Route exact path="/home/role/editRole/:id" component={checkPermission(ROLE_VALUES.EDITROLE) ? EditRole : RestrictedPage} />
                <Route exact path="/home/waivers/:id" component={checkPermission(ROLE_VALUES.WAIVERS) ? WaiverDetail : RestrictedPage} />
                <Route path="/restrictedPage" component={RestrictedPage} />
                <Route path="/pageNotFound" component={PageNotFound} />
                <Route path="*"  >
                    <Redirect to="/pageNotFound" />
                </Route>
            </Switch>
        </Router>
    </>)

}

export default DetailRoute;