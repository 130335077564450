import {
  PATHS,
  API
} from "./constants";

function getEWAApplicationListing(data, cb) {
  API.POST(PATHS.EWA.APPLICATION_LISTING(), data, {}, cb);
}

function searchEWAApplications(searchType, searchText, cb) {
  API.POST(PATHS.EWA.SEARCH_APPLICATIONS(searchType, searchText), {}, {}, cb);
}

function getEWAUserDetail(applicationId, cb) {
  API.GET(PATHS.EWA.USER_DETAIL(applicationId), {}, {}, cb);
}

function getEWAUserKyc(applicationId, cb) {
  API.GET(PATHS.EWA.KYC(applicationId), {}, {}, cb);
}

function getEWADigilockerDetail(applicationId, cb) {
  API.GET(PATHS.EWA.DIGILOCKER_DETAIL(applicationId), {}, {}, cb);
}

function getEWABankDetail(applicationId, cb) {
  API.GET(PATHS.EWA.BANK_DETAIL(applicationId), {}, {}, cb);
}

function getEWALineDetail(applicationId, cb) {
  API.GET(PATHS.EWA.LINE_DETAIL(applicationId), {}, {}, cb);
}

function getEWAApplicationDetail(applicationId, cb) {
  API.GET(PATHS.EWA.APPLICATION_DETAIL(applicationId), {}, {}, cb);
}

function getEWAStatementDetail(applicationId, cb) {
  API.GET(PATHS.EWA.STATEMENT_DETAIL(applicationId), {}, {}, cb);
}

function getEWAWithdrawalDetail(applicationId, cb) {
  API.GET(PATHS.EWA.WITHDRAWAL_DETAIL(applicationId), {}, {}, cb);
}

function getEWAPaymentDetail(applicationId, cb) {
  API.GET(PATHS.EWA.PAYMENT_DETAIL(applicationId), {}, {}, cb);
}

function getEWATransactionDetail(applicationId, cb) {
  API.GET(PATHS.EWA.TRANSACTION_DETAIL(applicationId), {}, {}, cb);
}

function getEWACurrentCycleDetail(applicationId, cb) {
  API.GET(PATHS.EWA.CURRENT_CYCLE_DETAIL(applicationId), {}, {}, cb);
}

export default {
  getEWAApplicationDetail,
  getEWAApplicationListing,
  getEWABankDetail,
  getEWACurrentCycleDetail,
  getEWADigilockerDetail,
  getEWAUserDetail,
  getEWALineDetail,
  getEWAStatementDetail,
  getEWAPaymentDetail,
  getEWAWithdrawalDetail,
  getEWATransactionDetail,
  getEWAUserKyc,
  searchEWAApplications,
}