import React from "react";
import {
    Typography,
    IconButton,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import Alert from "./../../components/alert";
import { convertTimestamptoDate } from "./../../utils/helper";
import ContentLoader from "../../components/contentLoader";
import CustomModal from "../../components/modal";
import CreateWaiver from "../../components/loan/createWaiver";
import { getLocalStorage } from "../../utils/localStorage";
import { EWAService } from "../../service";

const COLS = [
    { key: "withdrawal-id", align: "left", label: "WITHDRAWAL ID", value: "withdrawalId", type: "text", sort: false, fn: (v) => v ?? "-" },
    { key: "status", align: "left", label: "WITHDRAWAL STATUS", value: "status", type: "text", sort: false, fn: (v) => v ?? "-" },
    { key: "withdrawal-amount", align: "left", label: "WITHDRAWAL AMOUNT", value: "amount", type: "text", sort: false, fn: (v) => v ?? "-" },
    { key: "withdrawal-fee", align: "left", label: "WITHDRAWAL FEES", value: "withdrawalFee", type: "text", sort: false, fn: (v) => v ?? "-" },
    { key: "due-amount", align: "left", label: "DUE AMOUNT", value: "dueAmount", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "outstanding-principle", align: "left", label: "OUTSTANDING PRINCIPLET", value: "duePrinciple", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "outstanding-principle", align: "left", label: "OUTSTANDING PRINCIPLET", value: "duePrinciple", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "dpd-count", align: "left", label: "DPD", value: "dpdCount", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "due-date", align: "left", label: "DUE DATE", value: "dueDate", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v) },
    { key: "late-fee", align: "left", label: "LATE FEE", value: "lateFee", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "txn-date-and-time", align: "left", label: "TXN DATE & TIME", value: "transactionDateAndTime", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "txn-utr", align: "left", label: "UTR", value: "utr", type: "text", sort: false, fn: (v) => v ?? "-"},
  ]

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

class EWAWithdrawalDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            data: [],
            hasError: false,
            errorMessage: "",
            isOpen: false,
        }
    }

    componentDidMount() {
        this.fetchEWAStatement();
    }

    fetchEWAStatement = () => {

        const { loading } = this.state;
        const { id } = this.props;

        if (!loading) {
            this.setState({
                loading: true
            })
        }

        EWAService.getEWAWithdrawalDetail(id,
            (res) => {
                if (res && res.success) {
                    this.setState({
                        loading: false,
                        data: res.response.withdrawalDetails,
                    })
                } else {
                    this.setState({
                        hasError: true
                    })
                }
            }
        )
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    handleClose = _ => {
        this.setState({
            isOpen: false
        })
    }

    handleField = (event) => {
        const { name, value } = event.target;
        console.log(name,value)
        this.setState({
            [name]: value
        })
    }

    openModal = (evt,data) => {
        const { statementId ,bounceFee,lateFee, lateFeePaid, bounceFeePaid}=data;
        let sumOfWaive = (bounceFee +lateFee) - (lateFeePaid + bounceFeePaid)
        this.setState({
            statementId:statementId,
            sumOfWaive:sumOfWaive,
            isOpen: true
        })
        evt.stopPropagation();
    }

    handleCols = (data, column, evt) => {
        const { classes,bankTransfer } = this.props;
        const { type } = column;
        const { role} = getLocalStorage("LmsUserDetails");

        const checkPermission = (PERMISSIONS)=>{
            let conditionCheck = false;
                for(let item of PERMISSIONS){
                    if(role.includes(item)){
                        conditionCheck=true;
                        break;
                    }
            }

            return conditionCheck;
        }
        switch (type) {
            case "raiseWaiver":
                return data.isButton && !bankTransfer && checkPermission(["ROLE_ADMIN","ROLE_CRM_ADMIN","ROLE_CE"])? <>
                <button
                    className={classes.linkBtn}
                    onClick={(evt) => this.openModal(evt, data, type)}
                >Waive Amount</button>
                </>  :"-"

            default:
                return column.fn(data[column.value])
        }
    }

    createTable = ( data,cols,loading)=>{
        const { classes} = this.props;
        return (
        <>
         <TableContainer className={classes.tableContainer} >
                <Table stickyHeader className={classes.table} aria-label="simple-table">
                    <TableHead className={classes.tableHead}>
                        <TableRow className={classes.tableRow}>
                            {cols.map(it => <TableCell key={it.key}>
                                {it.label}
                            </TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        { loading ? <ContentLoader numberOfLines={4} module="statement"/> :
                            data.map(it => <StyledTableRow className={classes.tableRow}>
                                {cols.map(col => <TableCell className={classes.tableCell}>
                                    {this.handleCols(it, col)}</TableCell>
                                )}
                                </StyledTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>)
    }

    render() {
        const { loading, data, hasError, errorMessage,isOpen,statementId,sumOfWaive} = this.state;
        const { classes, handleClose,id } = this.props;
        // const dat = this.findLatestStatement(data)
        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >WITHDRAWAL DETAIL</Typography>
                    {this.createTable(data,COLS,loading)}

                    <CustomModal
                        isOpen={isOpen}
                        handleClose={this.handleClose}
                    >
                        <CreateWaiver
                          handleClose={this.handleClose}
                          LOAN_APPLICATION_ID={id}
                          statementId={statementId}
                          sumOfWaive={sumOfWaive}
                          typeOfWaiver={"LOAN_WAIVER"}
                        />
                    </CustomModal >
                </div>

                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    tableContainer: {
        height: "calc(100vh - 280px)",
        // height: "auto"
    },
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll"
    },
    FormContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "10% 15%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    },
    linkBtn: {
        "&:hover": {
            cursor: "pointer"
        },
        background:"rgb(16,12,109)",
        color:"white",
        height: 40,
        width: 120,
        borderRadius:"15px"
    },
    link: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    tableHead:{
        "& .MuiTableCell-head": {
            color: "black",
            fontWeight:"bold",
            backgroundColor: "rgb(236,246,255)"
        },
    },
    btnContainer: {
        background: "#fff",
        listStyle: "none",
        margin:"0px 40px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
    },
    container: {
        padding: "0 60px"
    },
    backBar: {

    },
    barTitle:{
        margin:5,
        padding:10
    },
    backIcon: {
        marginLeft: 10
    },
    header:{
        marginTop: 30,
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    fieldLabel: {
        fontSize: 18,
        fontFamily: "inherit",
        color: "#9E9E9E"
    },
    fieldValue: {
        fontSize: 17,
        fontFamily: "inherit",
        whiteSpace: 'break-spaces'
    },
    singleHead: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: 30,
        borderBottom: "1px solid"
    },
    singleRowContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: 40,

    },
    borderContainer: {
        border: "1px solid"
    },
    singleHeadCell: {
        width: 200,
        textAlign: "left",
        fontSize:25,
        padding:"20px 50px 10px",

    },
    singleRowCell: {
        width: 200,
        textAlign: "center"
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
        height: 40,
        maxHeigth:80,
    },

}

export default withStyles(styles)(EWAWithdrawalDetail);